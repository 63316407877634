<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.3587 3.72471C13.5806 3.9466 13.6008 4.29382 13.4192 4.5385L13.3587 4.6086L7.96758 9.99999L13.3587 15.3914C13.5806 15.6133 13.6008 15.9605 13.4192 16.2052L13.3587 16.2753C13.1368 16.4972 12.7896 16.5173 12.5449 16.3358L12.4748 16.2753L6.64147 10.4419C6.41958 10.22 6.39941 9.87282 6.58096 9.62815L6.64147 9.55805L12.4748 3.72471C12.7189 3.48064 13.1146 3.48064 13.3587 3.72471Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BackIcon",
};
</script>
