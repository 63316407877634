<template>
  <div class="passages scrolling-tables-static">
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Event log") }}</h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
      @changeFilterElement="changeFilterElement"
      ref="refFilterComponent"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="DATE"
      :sort-desc="true"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading="tableLoading"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Records per page') + ':',
        'items-per-page-options': [15, 25, 50, 100],
      }"
      class="table-striped  passages-table"
      ref="refTableObjects"
      :options.sync="tableOptions"
    >
      <template #[`item.DATE`]="{ item }">
        <span>{{ dateHelpers.getFormattedDateCustom(item.DATE, 'DD.MM.YYYY HH:mm:ss') }}</span>
      </template>
      <template #[`item.REQUEST_ID`]="{ item }">
        <div @click="routerLink(requestsMenu.link, item)" class="passages-link">
          {{ item.REQUEST_ID }}
        </div>
      </template>
      <template #[`item.REQUEST_V_NAME`]="{ item }">
        <div @click="routerLink(requestsMenu.link, item)" class="passages-link">
          {{ item.REQUEST_V_NAME }}
        </div>
      </template>
      <template #[`item.IS_PASSED`]="{ item }">
        <v-icon v-if="item.IS_PASSED">$check</v-icon>
      </template>
      <template #[`item.DEVICE_TYPE`]="{ item }">
        {{ $t(DEVICE_TYPE.find((elem) => elem.value == item.DEVICE_TYPE)?.text) }}
      </template>
      <template #[`item.IS_ENTRY`]="{ item }">
        <span
          :class="{
            'green--text': item.IS_ENTRY,
            'red--text': !item.IS_ENTRY,
          }"
        >
          {{ item.IS_ENTRY ? $t("Entry") : $t("Exit") }}
        </span>
      </template>
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <div
          @click="showVideoDialog(item)"
          v-if="item.DATA"
          >
            <v-icon
              class="mr-4"
              color="primary"
              size="24"
            >
              $videoIcon
            </v-icon>
          </div>
          <div
          @click="showImgDialog(item)"
          v-if="item.DATA?.car_shot"
          class="d-block">
            <v-tooltip bottom color="primary">
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mr-4"
                  v-bind="attrs"
                  color="primary"
                  size="24"
                  v-on="on"
                >
                  $pictureIcon
                </v-icon>
              </template>
              {{ $t("Car photo") }}
            </v-tooltip>
          </div>
          <v-tooltip bottom color="primary"  v-if="item.DATA">
            <template #activator="{ on, attrs }">
              <v-icon
                class="mr-4"
                v-bind="attrs"
                color="primary"
                size="24"
                v-on="on"
                @click="showInfoDialog(item)"
              >
                $informationIcon
              </v-icon>
            </template>
            {{ $t("Information") }}
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <InfoDialog
      :isDialogActive="isInfoDialogShow"
      @close-dialog="isInfoDialogShow = false"
      :information="currentPassageInformation"
    />
    <AppDialogImg
    :is-dialog-img-show="isDialogImgShow"
    @close-dialog="isDialogImgShow = false"
    :img-url="imgUrl"/>
    <AppDialogWrapper
    :isDialogShow="isDialogVideoShow"
    @close-dialog="isDialogVideoShow = false"
    :setting="dialogWrapperSetting"
    >
      <VideoWebSocket :item="objectItemDialog"></VideoWebSocket>
    </AppDialogWrapper>
  </div>
</template>

<script>
import moment from 'moment/moment';
import dateHelpers from '@/helpers/dateHelpers';
import api from '@/api';
import { requestsMenu } from '@/components/main/data.header';
import { mapGetters, mapActions } from 'vuex'
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { tableHeaders, filterModel } from './data.passages';
import { DEVICE_TYPE } from '@/constants';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import InfoDialog from './infoDialog.vue';
import { isJsonString } from '@/helpers/helpers';
import AppDialogImg from '@/components/blocks/AppDialogImg.vue';
import AppDialogWrapper from '@/components/blocks/AppDialogWrapper.vue';
import VideoWebSocket from '@/components/blocks/VideoWebSocket.vue';

export default {
  name: 'PassagesPage',
  components: { FilterComponent, InfoDialog, AppDialogImg, AppDialogWrapper, VideoWebSocket },
  data() {
    return {
      tableOptions: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
      DEVICE_TYPE,
      dateHelpers,
      tableHeaders,
      filterModel,
      dataFilters: FilterComponent.props.data.type,
      filterDateStart: null,
      filterDateEnd: null,
      filterByStatus: '',
      filterIsEmpty: false,
      organizations: [],
      showFilters: false,
      tableItems: [],
      tableLoading: false,
      tableCurrentItems: [],
      selectedBrandElement: [],
      isDialogImgShow: false,
      objectItemDialog: {},
      isDialogVideoShow: false,
      dialogWrapperSetting: {
        dialogMaxWidth:'1400px'
      },
      isInfoDialogShow: false,
      currentPassageInformation: '',
      requestsMenu: requestsMenu,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    dataTest() {
      return this.tableItems.filter(item => item.DATA);
    },
    imgUrl() {
      const url = (this.objectItemDialog.RECOGNITION?.ip ?? '') + this.objectItemDialog.DATA?.car_shot;
      return url ?? this.objectItemDialog.DATA?.car_shot;
    },
    ...mapGetters([
      'carsGroupedByBrand',
      'carsBrands',
      'getEmergencyServices',
      'getListGroupOpenType',
    ]),
  },
  mounted() {
    if (this.$route.query.identifier) {
      filterModel[this.getIndexFromFilter('IDENTIFIER')].data = this.$route.query.identifier;
      filterModel[this.getIndexFromFilter('DATE_START')].data = null;
      filterModel[this.getIndexFromFilter('DATE_END')].data = null;
    }
    this.applyFilter();
    api.objects.getAll().then((res) => {
      res.data.DATA.forEach((object) => {
        filterModel[this.getIndexFromFilter('OBJECT_ID')]?.items.push({
          text: object.NAME,
          value: object.ID,
        });
      });
    });
    api.devices.getAll().then((res) => {
      res.data.DATA.forEach((device) => {
        filterModel[this.getIndexFromFilter('DEVICE_ID')]?.items.push({
          text: device.NAME,
          value: device.ID,
        });
      });
    });
    api.externalSystems.getAll().then((res) => {
      res.data.DATA.forEach((system) => {
        filterModel[this.getIndexFromFilter('SYSTEM_ID')]?.items.push({
          text: system.NAME,
          value: system.ID,
        });
      });
    });
    if (this.carsBrands.length) {
      this.addBrandToFilter()
    } else {
      this.$store.dispatch('getCars').then(() => this.addBrandToFilter());
    }

    if (!this.getListGroupOpenType?.length) {
      api.groupOpenType.getList().then((res) => {
        this.updateListGroupOpenType(res.data.DATA);
        this.addGroupOpenType();
      })
    } else {
      this.addGroupOpenType();
    }

    if (this.getEmergencyServices.length) {
      this.addEmergencyServicesToFilter()
    } else {
      this.$store.dispatch('getEmergencyServices').then(() => this.addEmergencyServicesToFilter());
    }
  },
  methods: {
    ...mapActions(['updateListGroupOpenType']),
    addGroupOpenType() {
      this.getListGroupOpenType.forEach((item) => {
        filterModel[this.getIndexFromFilter('GROUP_OPEN_TYPE')]?.items.push({
          text: item.NAME,
          value: item.ID,
        });
      })
    },
    applyFilter(data) {
      this.tableOptions.page = 1;
      this.dataFilters = data;
      this.$store.commit(COMMON_SET_LOADING);
      const object_id = this.getValueFromFilter('OBJECT_ID');
      const device_id = this.getValueFromFilter('DEVICE_ID');
      const request_id = this.getValueFromFilter('REQUEST_ID');
      const is_entry = this.getValueFromFilter('INSIDE');
      const date_s = this.getDateTime('DATE_START');
      const date_po = this.getDateTime('DATE_END');
      const is_passed = this.getValueFromFilter('IS_PASSED');
      const limit_po = this.getValueFromFilter('LIMIT_PO');
      const identifier = this.getValueFromFilter('IDENTIFIER');
      const brand = this.getValueFromFilter('CURRENT_BRAND_ID');
      const model = this.getValueFromFilter('CURRENT_MODEL_ID');
      const emergency = this.getValueFromFilter('EMERGENCY_SERVICES');
      const group_open_type = this.getValueFromFilter('GROUP_OPEN_TYPE');
      api.passages
        .searchСar({
          object_id,
          device_id,
          request_id,
          // TODO: костыль для обхода типов в БД решить в сентябре с ДБ и беком
          is_entry: typeof is_entry === 'boolean' ? Number(is_entry) : is_entry,
          date_s,
          date_po,
          // TODO: костыль для обхода типов в БД решить в сентябре с ДБ и беком
          is_passed: typeof is_passed === 'boolean' ? Number(is_passed) : is_passed,
          limit_s: 0,
          limit_po,
          identifier,
          brand,
          model,
          emergency,
          group_open_type,
        })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },

    changeFilterElement(element) {
      if (element.name === 'CURRENT_BRAND_ID') {
        this.selectedBrandElement = element;
        this.addModelToFilter();
          filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].data = null
      }
    },
    carsModels() {
      if (!this.selectedBrandElement.data) {
        filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled = true;
        return []
      }

      filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled = false;
      return this.$store.getters.carsGroupedByBrand[this.selectedBrandElement.data].map(car => ({
        text: car.NAME,
        value: car.ID,
        translate: car.MULTI_DATA,
      }));
    },

    getValueFromFilter(field) {
      return (this.dataFilters || this.filterModel)?.find((item) => item.name === field).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    getDateTime(field) {
      const dateTime = this.getValueFromFilter(field);
      return dateTime && moment(dateTime).format('YYYY-MM-DD HH:mm:00');
    },
    showInfoDialog(item) {
      this.currentPassageInformation = isJsonString(item.DATA) ? item.DATA : JSON.stringify(item.DATA, null, 2);
      this.isInfoDialogShow = true;
    },
    showImgDialog(item) {
      this.objectItemDialog = item;
      this.isDialogImgShow = true;
    },
    showVideoDialog(item) {
      this.objectItemDialog = item;
      this.isDialogVideoShow = true;
    },
    addEmergencyServicesToFilter() {
      this.getEmergencyServices.forEach((system) => {
      filterModel[this.getIndexFromFilter('EMERGENCY_SERVICES')]?.items.push({
        text: system.NAME,
        value: system.ID,
      });
    });
    },
    addModelToFilter() {
      filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].items = this.carsModels(this.selectedBrandElement);
    },
    addBrandToFilter() {
      this.carsBrands.forEach((system) => {
        filterModel[this.getIndexFromFilter('CURRENT_BRAND_ID')]?.items.push({
          text: system.text,
          value: system.value,
          translate: system.translate,
        });
      });
    },
    routerLink(path, item) {
      this.$router.push({ path, query: { identifier: item.IDENTIFIER } });
    },
  },
};
</script>

<style lang="scss">
.passages-link {
  font-weight: 500;
  color: #0060ae;
  cursor: pointer;
}
</style>
