<template>
  <v-dialog max-width="450" :value="isShow" @click:outside="$emit('close-dialog')">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ title }}
        </h3>
      </v-card-title>

      <v-card-text style="max-width: 370px; margin: 0 auto" class="text-center pb-16">
        <v-form ref="carForm" v-model="formValid" @keyup.native.enter="formSubmit">
          <div>
            <v-text-field
              v-model="carForm.NAME"
              :rules="carFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>

            <v-text-field
              v-model="carForm.SID"
              :rules="carFormRules.SID"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Symbolic identifier") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
          </div>

          <v-btn
            depressed
            block
            color="primary"
            :class="{'btn_disabled': !formValid}"
            @click="formSubmit"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';

export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    carBrand: {
      type: String,
      default: ''
    },
    carBrandId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      formValid: false,

      carForm: {
        NAME: '',
        SID: '',
      },

      carFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        SID: [(v) => !!v || `${this.$t('You need to fill in «Symbolic identifier»')}.`],
      },
    }
  },
  computed: {
    title() {
      if (this.type === 'add-model' && this.carBrand) {
        return `${this.$t('Add new car model')} ${this.carBrand}`
      }

      if (this.type === 'add-brand') return this.$t('Add new car brand');

      return this.$t('Add car');
    },
  },
  methods: {
    formSubmit() {
      if (this.type === 'add-model') {
        this.addCarModel();
      }

      if (this.type === 'add-brand') {
        this.addCarBrand();
      }
    },
    async addCarModel() {
      try {
        const response = await api.referenceBooks.addCarModel({ NAME: this.carForm.NAME, SID: this.carForm.SID, BRAND_ID: this.carBrandId });
        this.$emit('add-car-model-or-brand', response.data.DATA);
        this.$emit('close-dialog');
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      }
    },
    async addCarBrand() {
      try {
        const response = await api.referenceBooks.addCarBrand({ NAME: this.carForm.NAME, SID: this.carForm.SID });
        this.$emit('add-car-model-or-brand', response.data.DATA);
        this.$emit('close-dialog');
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      }
    }
  },
  watch: {
    isShow() {
      for (const key of Object.keys(this.carForm)) {
        this.carForm[key] = '';
      }

      if (this.isShow && this.$refs.carForm) {
        this.$refs.carForm.resetValidation();
      }
    }
  }
}
</script>
