import axios from 'axios';

export const getAllCars = () => axios({
  url: '/directory/dir-brands',
  method: 'GET'
});

export const addCarBrand = (carBrand) => axios({
  url: '/directory/dir-brands',
  method: 'POST',
  data: {
    name: carBrand.NAME,
    sid: carBrand.SID
  }
});

export const addCarModel = (carBrand) => axios({
  url: '/directory/dir-brands',
  method: 'POST',
  data: {
    name: carBrand.NAME,
    sid: carBrand.SID,
    brand_id: carBrand.BRAND_ID
  }
});

export const deleteModelOrBrand = (data) => axios({
  url: '/directory/dir-brands',
  method: 'DELETE',
  data: {
    brand_id: data.type === 'brand' ? data.ID : null,
    model_id: data.type === 'model' ? data.ID : null
  }
});

export const getCarAccess = (accessType) => axios({
  url: '/directory/car-access',
  method: 'GET',
  params: {
    access_type: accessType
  }
});

export const addCarAccess = (carAccessData) => axios({
  url: '/directory/car-access',
  method: 'POST',
  data: {
    access_type: carAccessData.ACCESS_TYPE,
    car_num: carAccessData.CAR_NUM,
    model_id: carAccessData.CURRENT_MODEL_ID
  }
});

export const deleteCarAccess = (id) => axios({
  url: `/directory/car-access/${id}`,
  method: 'DELETE',
  data: id
});

export const getEmergency = () => axios({
  url: '/directory/dir-emergency',
  method: 'GET'
});

export const changeEmergencyStatus = (emergency) => axios({
  url: `/directory/dir-emergency/access/${emergency.ID}`,
  method: 'PUT',
  data: {
    id: emergency.ID,
    free_entrance: emergency.FREE_ENTRANCE
  }
});
