<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fill-rule="evenodd" d="M10.944 1.25h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238a.75.75 0 0 1-1.06 1.06c-.424-.422-1.004-.676-2.01-.811-1.027-.138-2.382-.14-4.289-.14h-2c-1.907 0-3.261.002-4.29.14-1.005.135-1.585.389-2.008.812-.423.423-.677 1.003-.812 2.009-.138 1.028-.14 2.382-.14 4.289v4c0 1.907.002 3.262.14 4.29.135 1.005.389 1.585.812 2.008.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.289.14h2c1.907 0 3.262-.002 4.29-.14 1.005-.135 1.585-.389 2.008-.812.696-.696.907-1.777.943-4.309a.75.75 0 0 1 1.5.022c-.035 2.427-.192 4.158-1.382 5.348-.749.748-1.698 1.08-2.87 1.238-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433V9.944c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238 1.14-.153 2.595-.153 4.433-.153Zm7.17 5.796a2.716 2.716 0 1 1 3.84 3.84L17.2 15.643a7.025 7.025 0 0 1-.63.587c-.23.18-.48.334-.744.46-.224.107-.46.185-.806.3l-2.084.695a1.28 1.28 0 0 1-1.62-1.62l.681-2.04.014-.043c.116-.347.194-.582.301-.806a4.03 4.03 0 0 1 .46-.744c.153-.196.328-.371.587-.63l.031-.031 4.724-4.724Zm2.78 1.06a1.216 1.216 0 0 0-1.72 0l-.182.182c.01.033.021.07.034.107.094.27.273.63.611.968a2.553 2.553 0 0 0 1.075.645l.182-.182a1.216 1.216 0 0 0 0-1.72Zm-1.328 3.048a4.083 4.083 0 0 1-.99-.73 4.083 4.083 0 0 1-.73-.99L14.45 12.83c-.301.301-.407.409-.496.523-.113.145-.21.302-.289.467-.062.131-.111.274-.246.678l-.4 1.2.283.283 1.2-.4c.404-.135.547-.184.678-.246.165-.08.322-.176.467-.289.114-.089.222-.195.523-.496l3.396-3.396ZM7.25 9A.75.75 0 0 1 8 8.25h6.5a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 9Zm0 4a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'WritingIcon',
};
</script>
