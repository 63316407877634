import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { USER_CHECK_SESSION } from '../store/types/action-types';

import ReportsLayout from '../views/reports';
import ReportsMain from '../views/reports/reportMain';
import ReportPasses from '../views/reports/reportPasses';
import ReportEvent from '../views/reports/reportEvent';
import LoginPage from '../views/LoginPage.vue';
import PassagesPage from '../views/passages';
import AdministrationLayout from '../views/administration';
import AccessZonesPage from '../views/administration/accessZones';
import EquipmentsPage from '../views/administration/equipments';
import ExternalSystemsPage from '../views/administration/externalSystems';
import UsersPage from '../views/administration/users';
import ObjectsPage from '../views/objects';
import SettingsPage from '../views/administration/settings';
import SelfRegistrationPage from '../views/selfRegistration';
import CheckRequest from '../views/checkRequest';
import Gates from '../views/administration/gates';
import Roles from '../views/administration/roles';
import ExternalReaders from '../views/administration/externalReaders';
import Servers from '../views/administration/servers';
import ReferenceBooks from '../views/administration/referenceBooks';

import middleware from './middleware/index';
// import guest from "./middleware/guest";
import auth from './middleware/auth';
import admin from './middleware/admin';
import objects from './middleware/objects';
import requests from './middleware/requests';
import passages from './middleware/passages';

Vue.use(VueRouter);

const isMobile = store.getters.isMobile();

const routes = [
  {
    path: '/',
    name: 'SelfRegistration',
    component: SelfRegistrationPage,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [auth] },
    component: LoginPage,
  },
  {
    path: '/administration',
    name: 'Administration',
    meta: { isNeedAuth: true, middleware: [admin] },
    component: AdministrationLayout,
    redirect: '/administration/users',
    children: [
      {
        path: 'users',
        name: 'Users',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: UsersPage,
      },
      {
        path: 'external-systems',
        name: 'ExternalSystems',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: ExternalSystemsPage,
      },
      {
        path: 'equipments',
        name: 'Equipments',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: EquipmentsPage,
      },
      {
        path: 'access-zones',
        name: 'AccessZones',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: AccessZonesPage,
      },
      {
        path: 'gates',
        name: 'Gates',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: Gates,
      },
      {
        path: 'roles',
        name: 'Roles',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: Roles,
      },
      {
        path: 'external-readers',
        name: 'ExternalReaders',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: ExternalReaders
      },
      {
        path: 'servers',
        name: 'Servers',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: Servers
      },
      {
        path: 'reference-books',
        name: 'ReferenceBooks',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: ReferenceBooks
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: SettingsPage,
      },
    ],
  },
  {
    path: '/objects',
    name: 'Objects',
    meta: { isNeedAuth: true, middleware: [objects], layout: 'admin' },
    component: ObjectsPage,
  },
  {
    path: '/requests',
    name: 'Pass',
    meta: { isNeedAuth: true, middleware: [requests], layout: isMobile ? 'main' : 'admin'},
    component: () => isMobile ? import('../views/mobileRequests')
    : import('../views/requests'),
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: { isNeedAuth: true, layout: 'admin' },
    component: ReportsLayout,
    redirect: '/reports/main',
    children: [
      {
        path:'/reports/main',
        name: 'ReportsMain',
        component: ReportsMain,
        meta: { isNeedAuth: true, layout: 'admin' },
      },
      {
        path: 'passes',
        name: 'ReportPasses',
        meta: { isNeedAuth: true, layout: 'admin' },
        component: ReportPasses,
      },
      {
        path: 'event',
        name: 'ReportEvent',
        meta: { isNeedAuth: true, layout: 'admin' },
        component: ReportEvent,
      },
    ]
  },

  {
    path: '/passages',
    name: 'Passages',
    meta: { isNeedAuth: true, middleware: [passages], layout: 'admin' },
    component: PassagesPage,
  },
  {
    path: '/check-request/:id',
    name: 'checkRequest',
    component: CheckRequest,
  },
  {
    path: '/*',
    name: 'NotFound',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const userRole = store.state.user.model.ROLE;
  if (to.meta.isNeedAuth) {
    if (!isLoggedIn) {
      return next('/login');
    }
    if (userRole === undefined) {
      return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
    }
    return middleware(to, from, next);
  }

  if (isLoggedIn && userRole === undefined) {
    return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
  }
  return middleware(to, from, next);
});
export default router;
