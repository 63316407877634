export default {
  state: {
    listGroupOpenType: [],
  },
  getters: {
    getListGroupOpenType: state => state.listGroupOpenType
  },
  mutations: {
    setListGroupOpenType(state, newListGroupOpenType) {
      state.listGroupOpenType = newListGroupOpenType;
    }
  },
  actions: {
    updateListGroupOpenType({ commit }, newListGroupOpenType) {
      commit('setListGroupOpenType', newListGroupOpenType);
    }
  },
}
