<template>
  <v-dialog
    :value="isShow"
    max-width="580"
    content-class="overflow-hidden"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h2 class="font-weight-medium">
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchObjects"
          class="mb-5"
          append-icon="mdi-magnify"
          :label="$t('Name')"
          outlined
          dense
          hide-details
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">
              $search
            </v-icon>
          </template>
        </v-text-field>
        <v-data-table
          class="table-no-border table-no-head object-roles__table"
          :value="roleObjects"
          :headers="translatedHeaders"
          :items="roleObjects"
          :search="searchObjects"
          checkbox-color="primary"
          :item-key="itemKey"
          :no-data-text="`${title} ${$t('not found')}`"
          :no-results-text="`${title} ${$t('not found')}`"
          disable-pagination
          hide-default-footer
          height="550"
          sort-by="isSelectable"
        >

          <template #[`header.RADIOS`]>
            <div class="object-roles__radios">
              <div class="object-roles__radio-item object-roles__small-header_allowed">
                {{ $t('Allowed') }}
              </div>
              <div class="object-roles__radio-item object-roles__small-header_forbidden">
                {{ $t('Forbidden') }}
              </div>
            </div>
          </template>
          <template #[`item.RADIOS`]="{ item }">
            <v-radio-group
              v-model="item.IS_SELECT"
              row
            >
              <div class="object-roles__radios">
                <v-radio
                  class="object-roles__radio-item"
                  color="primary"
                  :value="true"
                >
                </v-radio>
                <v-radio
                  class="object-roles__radio-item"
                  color="primary"
                  :value="false"
                />
              </div>
            </v-radio-group>
          </template>
        </v-data-table>

        <v-btn depressed color="primary" width="100%" @click="saveObjects">
          {{ $t("Save") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';

export default {
  name: 'ObjectsRolesDialog',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    id: {
      type: Number
    },
  },
  data() {
    return {
      searchObjects: '',
      roleObjects: []
    }
  },
  computed: {
    translatedHeaders() {
      return this.headers.map((header) => ({ ...header, text: this.$t(header.text) }));
    },
  },
  methods: {
    closeDialog() {
      setTimeout(() => this.$emit('close-dialog', false), 200);
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
    },
    async getRoleObjects() {
      const response = await api.roles.objectRoleGetElements(this.id);
      return Array.isArray(response.data.DATA) ? response.data.DATA : [response.data.DATA];
    },
    async saveObjects() {
      const objectsData = this.roleObjects.map((object) => ({
        object_id: object.OBJECT_ID,
        is_bind: object.IS_SELECT
      }));

      try {
        await api.roles.objectRoleChangeElements(this.id, JSON.stringify(objectsData));
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      }

      this.closeDialog();
    }
  },
  watch: {
    async id() {
      if (this.isShow) {
        this.roleObjects = await this.getRoleObjects();
      }
    }
  }
}
</script>

<style lang="scss">
.object-roles {
  &__small-header {
    font-size: 10px !important;
    font-weight: 400 !important;

    &_allowed {
      color: $accent-green !important;
    }

    &_forbidden {
      color: $accent-red !important;
    }
  }

  &__radios {
    display: flex;
    width: 100%;
  }

  &__radio-item {
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    margin-right: 0 !important;

    .v-input--selection-controls__input {
      margin-right: 0;
    }

    svg {
      color: $primary;
    }
  }

  &__table {
    .v-input--selection-controls {
      padding: 0;
      margin-top: 0;
    }

    .v-input__slot {
      margin-bottom: 0;
    }

    .v-messages {
      display: none;
    }

    th, td {
      padding: 0 !important;
    }
  }
}
</style>
