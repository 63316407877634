import dateHelpers from '@/helpers/dateHelpers';

import {
  FLAG_IS_PASSED,
  FLAG_PASSAGE,
} from '@/constants';

export const tableHeaders = [
  {
    text: 'Date and time of travel',
    value: 'DATE',
    filterable: true,
    cellClass: 'font-weight-medium',
    width: '200px',
  },
  {
    text: 'Plate num',
    value: 'IDENTIFIER',
    filterable: true,
    width: '150',
  },
  {
    text: 'Brand',
    value: 'BRAND_NAME',
    filterable: false,
    sortable: false,
    width: '150',
  },
  {
    text: 'Model',
    value: 'MODEL_NAME',
    filterable: false,
    sortable: false,
    width: '150',
  },
  {
    text: 'Color schema',
    value: 'EMERGENCY_NAME',
    filterable: false,
    sortable: false,
    width: '160',
  },
  {
    text: 'ID of the pass',
    value: 'REQUEST_ID',
    filterable: true,
    width: '150',
  },
  {
    text: 'Full name of the visitor',
    value: 'REQUEST_V_NAME',
    filterable: true,
    width: '250px',
  },
  {
    text: 'The direction of travel',
    value: 'IS_ENTRY',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Type of device opening',
    value: 'GROUP_OPEN_TYPE_NAME',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'The passage has been completed',
    value: 'IS_PASSED',
    filterable: false,
    sortable: false,
    width: '160px',
  },
  {
    text: 'Object of visit',
    value: 'OBJECT_NAME',
    filterable: true,
    width: '200px',
  },
  {
    text: 'Device name',
    value: 'DEVICE_NAME',
    filterable: true,
    width: '250px',
  },
  {
    text: 'Error message',
    value: 'MESSAGE',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
  }

];

export const filterModel = [
  {
    name: 'OBJECT_ID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Object of visit',
  },
  {
    name: 'DEVICE_ID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Device name',
  },
  {
    name: 'INSIDE',
    type: 'select',
    data: null,
    items: FLAG_PASSAGE,
    label: 'The direction of travel',
  },
  {
    name: 'IS_PASSED',
    type: 'select',
    data: null,
    items: FLAG_IS_PASSED,
    label: 'The passage has been completed',
  },
  {
    name: 'DATE_START',
    type: 'date-time-picker',
    data: dateHelpers.getDaysAgo().toDate(),
    label: 'Date and time the records were created from',
  },
  {
    name: 'DATE_END',
    type: 'date-time-picker',
    data: dateHelpers.getToday().endOf('day').toDate(),
    label: 'Date and time the records were created till',
  },
  {
    name: 'IDENTIFIER',
    type: 'input-field',
    data: '',
    label: 'Plate num',
  },
  {
    name: 'REQUEST_ID',
    type: 'input-field',
    data: '',
    label: 'ID of the pass',
  },
  {
    name: 'CURRENT_BRAND_ID',
    type: 'autocomplete-translate',
    data: [],
    items: [],
    label: 'Car brand',
  },
  {
    name: 'CURRENT_MODEL_ID',
    type: 'autocomplete-translate',
    disabled:true,
    data: [],
    items: [],
    label: 'Car model',
  },
  {
    name: 'EMERGENCY_SERVICES',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Emergency services',
  },
  {
    name: 'LIMIT_PO',
    type: 'text-field-limit-po',
    altType: 'number',
    data: 500,
    label: 'Limitation of the number of records',
  },
  {
    name: 'GROUP_OPEN_TYPE',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Type of device opening',
  },
];
