<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="currentColor"/>
    <rect x="11.2" y="4.7998" width="1.6" height="14.4" rx="0.8" fill="currentColor"/>
    <rect x="19.2" y="11.2002" width="1.6" height="14.4" rx="0.8" transform="rotate(90 19.2 11.2002)" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'AddSquareIcon',
};
</script>
