import axios from 'axios';

export const search = (params) => axios({
  url: '/passages',
  method: 'GET',
  params,
});

export const searchСar = (params) => axios({
  url: '/passages/data-car',
  method: 'GET',
  params,
});

export const getShortList = (id) => axios({
  url: '/passages/short-list',
  method: 'GET',
  params: {
    identifier: id,
  },
});

export const getVideoParams= (id) => axios({
  url: `/passages/video-params/${id}`,
  method: 'GET',
});

export const getVideoLogout= (id) => axios({
  url: `/passages/video-params/logout/${id}`,
  method: 'GET',
});

