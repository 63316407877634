<template>
  <v-dialog :value="showDialog" max-width="580" @click:outside="closeDialog"  @keydown="keydownHandler">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper justify-center">
        <slot name="title">
          {{ $t("Confirm deletion?") }}
        </slot>
      </v-card-title>
      <v-card-text class="card__text card__text_center">
        <slot />
      </v-card-text>
      <v-card-actions class="card__actions">
        <v-btn class="card__action-btn" color="primary" outlined depressed @click="closeDialog">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          class="card__action-btn card__action-btn_white"
          color="red"
          :loading="loading"
          depressed
          @click="$emit('confirm')"
        >
          <slot name="confirmBtn">
            {{ $t("Delete") }}
          </slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmRemoveDialog',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: true,
    };
  },

  methods: {
    closeDialog() {
      this.showDialog = false;
      setTimeout(() => this.$emit('closeDialog', false), 200);
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
    },
  },
};
</script>
