<template>
<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m1.25 12c0-5.9371 4.8129-10.75 10.75-10.75 5.9371 0 10.75 4.8129 10.75 10.75 0 5.9371-4.8129 10.75-10.75 10.75-5.9371 0-10.75-4.8129-10.75-10.75zm10.75-9.25c-5.1086 0-9.25 4.1414-9.25 9.25 0 5.1086 4.1414 9.25 9.25 9.25 5.1086 0 9.25-4.1414 9.25-9.25 0-5.1086-4.1414-9.25-9.25-9.25z" clip-rule="evenodd" fill="currentcolor" fill-rule="evenodd"/>
<path d="m16.125 11.784c0.1667 0.0962 0.1667 0.3368 0 0.433l-6 3.4641c-0.16667 0.0962-0.375-0.024-0.375-0.2165v-6.9282c0-0.19245 0.20833-0.31273 0.375-0.21651l6 3.4641z" stroke="currentcolor" stroke-width="1.5"/>
</svg>
</template>

<script>
export default {
  name: 'ChangeArrowsIcon',
};
</script>
