import axios from 'axios';
import { isJsonString } from '@/helpers/helpers';

export const getAll = (params) => axios({
  url: '/reader-external',
  method: 'GET',
  params
});

export const create = (externalReaderData) => axios({
  url: '/reader-external',
  method: 'POST',
  data: {
    sid: externalReaderData.SID,
    name: externalReaderData.NAME,
    type: externalReaderData.TYPE,
    address: externalReaderData.ADDRESS,
    status: externalReaderData.STATUS,
    parameters: isJsonString(externalReaderData.PARAMETERS) ? externalReaderData.PARAMETERS : JSON.stringify(externalReaderData.PARAMETERS),
    is_entry: externalReaderData.IS_ENTRY,
    description: externalReaderData.DESCRIPTION,
    confirm_in: externalReaderData.CONFIRM_IN,
    confirm_out: externalReaderData.CONFIRM_OUT,
  }
});

export const edit = (externalReaderData) => axios({
  url: `/reader-external/${externalReaderData.ID}`,
  method: 'PUT',
  data: {
    id: externalReaderData.ID,
    sid: externalReaderData.SID,
    name: externalReaderData.NAME,
    type: externalReaderData.TYPE,
    address: externalReaderData.ADDRESS,
    status: externalReaderData.STATUS,
    parameters: isJsonString(externalReaderData.PARAMETERS) ? externalReaderData.PARAMETERS : JSON.stringify(externalReaderData.PARAMETERS),
    is_entry: externalReaderData.IS_ENTRY,
    description: externalReaderData.DESCRIPTION,
    confirm_in: externalReaderData.CONFIRM_IN,
    confirm_out: externalReaderData.CONFIRM_OUT,
  }
});

export const changeStatus = (id, status) => axios({
  url: `/reader-external/${id}/status`,
  method: 'PUT',
  data: {
    id, status
  }
});

export const remove = (id) => axios({
  url: `/reader-external/${id}`,
  method: 'DELETE',
  data: {
    id
  }
});
