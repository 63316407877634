<template>
  <div
    class="message-block message-block-big"
    :class="{ 'message-block_hide': !isMessageShow }"
  >
    <v-icon
      class="message-block__close-btn"
      color="black"
      size="8"
      @click="closeDialog"
    >
      $close
    </v-icon>
    <div class="message-block__body">
      <v-icon class="message-block__lock" color="red" size="50"> $lock </v-icon>
      <slot name="body" />
    </div>
    <button class="message-block__hide-btn" @click="hideDialog">
      Больше не показывать
    </button>
  </div>
</template>

<script>
export default {
  props: {
    nameInLocalStorage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMessageShow: true,
    };
  },
  mounted() {
    if (localStorage.getItem(this.nameInLocalStorage)) {
      this.isMessageShow = false;
    }
  },
  methods: {
    closeDialog() {
      this.isMessageShow = false;
    },
    hideDialog() {
      localStorage.setItem(this.nameInLocalStorage, true);
      this.isMessageShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-block {
  position: absolute;
  margin: 0 auto;
  top: 36%;
  min-height: 300px;
  right: 0;
  left: 0;
  z-index: 3;
  padding: 16px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 4px 13px 0 rgb(142 142 142 / 25%);
  
  &-big {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 12px;
  }

  &_hide {
    display: none;
  }

  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__hide-btn {
    display: block;
    margin: 0 auto;
    color: $primary;
    text-transform: uppercase;
  }
}
</style>
