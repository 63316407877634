<template>
  <div class="message-block" :class="{'message-block_hide' : !isMessageShow}">
    <v-icon
      class="message-block__close-btn"
      color="black"
      size="8"
      @click="closeDialog"
    >
      $close
    </v-icon>
    <div class="message-block__body">
      <slot name="body" />
    </div>
    <button class="message-block__hide-btn" @click="hideDialog">
      Больше не показывать
    </button>
  </div>
</template>

<script >
export default {
  props: {
    nameInLocalStorage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMessageShow: true,
    }
  },
  mounted() {
    if (localStorage.getItem(this.nameInLocalStorage)) {
      this.isMessageShow = false;
    }
  },
  methods: {
    closeDialog() {
      this.isMessageShow = false;
    },
    hideDialog() {
      localStorage.setItem(this.nameInLocalStorage, true);
      this.isMessageShow = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.message-block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 12px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 4px 13px 0 rgb(142 142 142 / 25%);

  &_hide {
    display: none;
  }

  &__close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;
  }

  &__body {
    margin-bottom: 12px;
    font-size: 12px;
  }

  &__hide-btn {
    display: block;
    margin-left: auto;
    color: $primary;
    text-transform: uppercase;
  }
}
</style>
