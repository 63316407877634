<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
      fill="currentcolor" />
    <path
      d="M16.125 11.7835C16.2917 11.8797 16.2917 12.1203 16.125 12.2165L10.125 15.6806C9.95833 15.7768 9.75 15.6566 9.75 15.4641L9.75 8.5359C9.75 8.34345 9.95833 8.22317 10.125 8.31939L16.125 11.7835Z"
      stroke="currentcolor" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: 'videoIcon',
};
</script>
