import { groupBy } from 'lodash-es';
import api from '@/api';

export default {
  state: {
    enums: []
  },
  getters: {
    getEnums: state => {
      return groupBy(state.enums, 'ENUM_SID');
    }
  },
  mutations: {
    updateEnums(state, newEnums) {
      state.enums = newEnums;
    }
  },
  actions: {
    async getEnums(context) {
      const response = await api.enums.getAll();
      context.commit('updateEnums', response.data.DATA);
    }
  }
};
