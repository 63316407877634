<template>
  <div>
    <v-tabs class="srp-tabs mb-5 border-bottom-0 d-flex flex-column">
      <v-row class="mb-5">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2">
            {{ $t("Roles") }}
          </h2>
        </v-col>
        <v-tab @click="roleType = 1">{{ $t("Functional") }}</v-tab>
        <v-tab @click="roleType = 2">{{ $t("Set of Gates") }}</v-tab>
        <v-col cols="auto">
          <v-btn depressed color="primary" @click="showNewRoleForm">
            <v-icon size="18" class="mr-2">$plus</v-icon>
            {{ $t("Add") }}
          </v-btn>
        </v-col>
        <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-btn
            depressed
            class="ml-4"
            color="primary"
            :outlined="filterIsEmpty"
            width="150"
            @click="showFilters = !showFilters"
          >
            <template v-if="!showFilters">
              <v-icon class="mr-2" size="20">$filter</v-icon>
              {{ $t("Filters") }}
            </template>
            <template v-else>
              <v-icon class="mr-2" size="20">$up</v-icon>
              {{ $t("Collapse") }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-tabs>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-show="roleType === ROLE_TYPES.functionalRole"
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showPermissionsAndProhibitionDialog(item)"
              >
                $writingIcon
              </v-icon>
            </template>
            {{ $t("Permissions") }}
          </v-tooltip>

          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-show="roleType === ROLE_TYPES.objectRole"
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showObjectsRolesDialog(item)"
              >
                $objects
              </v-icon>
            </template>
            {{ $t("Objects") }}
          </v-tooltip>
          <v-icon class="mr-4" color="red" @click="showRemoveRoleConfirm(item)">
            $delete
          </v-icon>
          <v-icon
            class="mr-4"
            :color="item.STATUS !== 2 ? 'red' : 'green'"
            @click="toggleRoleStatus(item)"
          >
            $lock
          </v-icon>
          <v-icon color="primary" @click="showEditRoleForm(item)">
            $edit
          </v-icon>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ $t(ROLE_STATUSES.find((elem) => elem.value == item.STATUS)?.text) }}
        </span>
      </template>
    </v-data-table>

    <FunctionalRolesDialog
      :is-dialog-active="isDialogActive"
      :role-type="roleType"
      :is-new-role-form="isNewRoleForm"
      :role-form-prop="roleForm"
      @close-dialog="isDialogActive = false"
      @form-submit="onFormSubmit"
    />

    <ConfirmRemoveDialog
      v-if="roleRemoveConfirm"
      :loading="roleRemoveLoading"
      @closeDialog="roleRemoveConfirm = false"
      @confirm="removeRole"
    >
      <template #title>
        {{ $t("Delete role") }} «{{ roleForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore role data") }}.
      </template>
    </ConfirmRemoveDialog>

    <PermissionsAndProhibition
      v-if="isPermissionsAndProhibitionDialogActive"
      :title="$t('Permissions and prohibition')"
      :is-editable="true"
      :id="roleForm.ID"
      @close-permissions-dialog="isPermissionsAndProhibitionDialogActive = false"
    />

    <ObjectsRolesDialog
      :is-show="isObjectsRolesDialogActive"
      :title="$t('Objects')"
      :headers="objectTableHeaders"
      item-key="NAME"
      :id="roleForm.ID"
      @close-dialog="isObjectsRolesDialogActive = false"
    />
  </div>
</template>

<script>
import api from '@/api';
import { ROLE_STATUSES, ROLE_TYPES } from '@/constants';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import PermissionsAndProhibition from '@/components/blocks/PermissionsAndProhibition.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import FunctionalRolesDialog from './functionalRolesDialog.vue';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import ObjectsRolesDialog from './objectsRolesDialog.vue';
import { filterModel, tableHeaders, objectTableHeaders } from './data.roles';

export default {
  name: 'Roles',
  components: { FilterComponent, FunctionalRolesDialog, ConfirmRemoveDialog, PermissionsAndProhibition, ObjectsRolesDialog },
  data() {
    return {
      ROLE_STATUSES,
      ROLE_TYPES,
      tableHeaders,
      objectTableHeaders,

      // roleForm
      roleForm: this.createRoleFormModel(),

      // filter
      filterModel,
      filterIsEmpty: true,
      showFilters: false,

      // table
      tableItems: [],
      tableLoading: false,

      // remove dialog
      roleRemoveConfirm: false,
      roleRemoveLoading: false,

      isNewRoleForm: true,
      isDialogActive: false,
      roleType: ROLE_TYPES.functionalRole,
      functionalRoles: [],
      objectRoles: [],

      isPermissionsAndProhibitionDialogActive: false,
      isObjectsRolesDialogActive: false
    }
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  async mounted() {
    this.$store.commit(COMMON_SET_LOADING);

    try {
      await this.updateRoleList(ROLE_TYPES.functionalRole);
      this.tableItems = this.functionalRoles;
    } finally {
      this.$store.commit(COMMON_SET_LOADING, false);
    }
  },
  watch: {
    async roleType() {
      if (this.roleType === ROLE_TYPES.functionalRole) {
        this.tableItems = this.functionalRoles;
        return;
      }

      if (!this.objectRoles.length) {
        this.$store.commit(COMMON_SET_LOADING);
        try {
          await this.updateRoleList(ROLE_TYPES.objectRole);
        } finally {
          this.$store.commit(COMMON_SET_LOADING, false);
        }
      }

      this.tableItems = this.objectRoles;
    }
  },
  methods: {
    async getRoles(roleType, params = {}) {
      const response = await api.roles.getAll(roleType, params);
      return response.data.DATA;
    },
    async updateRoleList(roleType, params = {}) {
      if (roleType === ROLE_TYPES.functionalRole) {
        this.functionalRoles = await this.getRoles(ROLE_TYPES.functionalRole, params);
        this.tableItems = this.functionalRoles;
      }

      if (roleType === ROLE_TYPES.objectRole) {
        this.objectRoles = await this.getRoles(ROLE_TYPES.objectRole, params);
        this.tableItems = this.objectRoles;
      }
    },
    createRoleFormModel(role = {}) {
      return {
        ID: role.ID ?? null,
        ROLETYPE: role.ROLETYPE ?? null,
        NAME: role.NAME ?? '',
        STATUS: role.STATUS ?? null,
        DESCRIPTION: role.DESCRIPTION ?? ''
      }
    },

    // filter
    applyFilter() {
      const status = this.getValueFromFilter('STATUS');
      const name = this.getValueFromFilter('NAME');

      this.updateRoleList(this.roleType, { name, status })
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },

    async toggleRoleStatus(item) {
      const status = item.STATUS === 1 ? 2 : 1;

      this.$store.commit(COMMON_SET_LOADING);
      try {
        const res = await api.roles.edit(item.ID, this.roleType, item.NAME, status, item.DESCRIPTION)
        this.$store.dispatch(SNACK_ADD_MESSAGE, {
          type: 'success',
          text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
        });
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      } finally {
        this.$store.commit(COMMON_SET_LOADING, false);
      }

      this.updateRoleList(this.roleType);
    },

    showPermissionsAndProhibitionDialog(role) {
      this.roleForm = this.createRoleFormModel({ ROLETYPE: this.roleType, ...role});
      this.isPermissionsAndProhibitionDialogActive = true;
    },

    async showObjectsRolesDialog(role) {
      this.roleForm = this.createRoleFormModel({ ROLETYPE: this.roleType, ...role});
      this.isObjectsRolesDialogActive = true;
    },

    // Add, Edit dialog
    showNewRoleForm() {
      this.roleForm = this.createRoleFormModel({ ROLETYPE: this.roleType });
      this.isNewRoleForm = true;
      this.isDialogActive = true;
    },
    showEditRoleForm(role) {
      this.roleForm = this.createRoleFormModel({ ROLETYPE: this.roleType, ...role});
      this.isNewRoleForm = false;
      this.isDialogActive = true;
    },
    async onFormSubmit(formData) {
      if (this.isNewRoleForm) {
        await api.roles.add(formData.ROLETYPE, formData.NAME, formData.STATUS, formData.DESCRIPTION);
      } else {
        await api.roles.edit(formData.ID ,formData.ROLETYPE, formData.NAME, formData.STATUS, formData.DESCRIPTION);
      }

      this.updateRoleList(this.roleType);
      this.isDialogActive = false;
    },

    // Remove dialog
    showRemoveRoleConfirm(item) {
      this.roleForm = this.createRoleFormModel({ ROLETYPE: this.roleType, ...item });
      this.roleRemoveConfirm = true;
    },
    async removeRole() {
      await api.roles.remove(this.roleForm.ID, this.roleForm.ROLETYPE);
      await this.updateRoleList(this.roleForm.ROLETYPE);
      this.roleRemoveConfirm = false;
    }
  }
}
</script>
