import axios from 'axios';

export const getAll = (params) => axios({
  url: '/gate',
  method: 'GET',
  params
});

export const create = (gate) => axios({
  url: '/gate',
  method: 'POST',
  data: {
    name: gate.NAME,
    status: gate.STATUS,
    server_id: gate.SERVER_ID,
    description: gate.DESCRIPTION
  }
});

export const edit = (gate) => axios({
  url: `/gate/${gate.ID}`,
  method: 'PUT',
  data: {
    id: gate.ID,
    name: gate.NAME,
    status: gate.STATUS,
    server_id: gate.SERVER_ID,
    description: gate.DESCRIPTION
  }
});

export const changeStatus = (id, status) => axios({
  url: `/gate/${id}/status`,
  method: 'PUT',
  data: {
    id, status
  }
});

export const remove = (id) => axios({
  url: `/gate/${id}`,
  method: 'DELETE',
  data: {
    id
  }
});

export const getDevices = (id) => axios({
  url: `/gate/${id}/devices`,
  method: 'GET',
  data: {
    id
  }
});
