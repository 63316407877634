<template>
  <v-dialog :value="isDialogShow" max-width="580" @click:outside="closeDialog" @keydown="keydownHandler">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ dialogTitle }}
        </h3>
      </v-card-title>
      <v-card-text class="mb-2">
        {{ dialogText }}
      </v-card-text>

      <v-card-actions class="justify-center pb-0">
        <v-btn class="px-7 py-3 mx-4" color="primary" outlined depressed @click="closeDialog">
          {{ $t("Close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  computed: {
    isDialogShow() {
      return this.$store.getters.getDefaultDialogStatus;
    },
    dialogTitle() {
      return this.$store.getters.getDefaultDialogTitle;
    },
    dialogText() {
      return this.$store.getters.getDefaultDialogText;
    }
  },
  methods: {
    closeDialog() {
      this.$store.getters.getDefaultDialogFunctionOnClose();
      this.$store.commit('changeDefaultDialogStatus', false);
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
    },
  }
}
</script>
