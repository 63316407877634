<template>
  <v-dialog
    :value="showDialog"
    :max-width="maxWidth"
    content-class="overflow-hidden"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper card__title-wrapper_checkbox-dialog">
        <h2 class="font-weight-medium">
          {{ title }}
        </h2>
        <v-chip color="green" text-color="white" label>
          {{ subtitle }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchObjects"
          append-icon="mdi-magnify"
          :label="$t('Name')"
          outlined
          dense
          hide-details
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">
              $search
            </v-icon>
          </template>
        </v-text-field>
        <v-data-table
          class="table-no-border table-no-head"
          :value="selectedObjects"
          :loading="tableLoading"
          :headers="headersWithoutPaddings"
          :items="[...selectedObjects, ...unselectedObjects]"
          :search="searchObjects"
          checkbox-color="primary"
          :item-key="itemKey"
          :no-data-text="`${title} ${$t('not found')}`"
          :no-results-text="`${title} ${$t('not found')}`"
          :show-select="showCheckboxes"
          disable-pagination
          hide-default-footer
          height="550"
          sort-by="isSelectable"
          :dense="isListDense"
          @item-selected="$emit('itemSelected', $event)"
        >
          <template v-if="disabledCheckbox" #[`item.data-table-select`]="{ isSelected }">
            <v-simple-checkbox
              :value="isSelected"
              :readonly="disabledCheckbox"
              :disabled="disabledCheckbox"
            />
          </template>
          <template #[`header.data-table-select`] />
          <template #[`header.${headers[0].value}`] />
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CheckboxListDialog',
  components: {},
  props: {
    objects: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    disabledCheckbox: {
      type: Boolean,
      default: false,
    },
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: 580
    },
    isListDense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: true,
      searchObjects: '',
      tableLoading: false,
    };
  },
  computed: {
    selectedObjects() {
      return this.objects.filter((item) => !!item.IS_ACCESS);
    },
    unselectedObjects() {
      return this.objects.filter((item) => !item.IS_ACCESS);
    },
    headersWithoutPaddings() {
      return [{ ...this.headers[0], class: 'px-1', cellClass: 'px-1' }];
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      setTimeout(() => this.$emit('closeDialog', false), 200);
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
    },
  },
};
</script>
