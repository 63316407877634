<template>
  <svg
    class="srp-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 2.1665C11.6094 2.1665 11.8333 2.39036 11.8333 2.6665V11.8332L12.9333 10.3665C13.099 10.1456 13.4124 10.1008 13.6333 10.2665C13.8542 10.4322 13.899 10.7456 13.7333 10.9665L11.7333 13.6332C11.6042 13.8053 11.3793 13.8756 11.1752 13.8075C10.971 13.7395 10.8333 13.5484 10.8333 13.3332V2.6665C10.8333 2.39036 11.0571 2.1665 11.3333 2.1665ZM4.83329 3.99984C4.83329 3.72369 5.05715 3.49984 5.33329 3.49984H8.66663C8.94277 3.49984 9.16663 3.72369 9.16663 3.99984C9.16663 4.27598 8.94277 4.49984 8.66663 4.49984H5.33329C5.05715 4.49984 4.83329 4.27598 4.83329 3.99984ZM3.49996 7.33317C3.49996 7.05703 3.72382 6.83317 3.99996 6.83317H8.66663C8.94277 6.83317 9.16663 7.05703 9.16663 7.33317C9.16663 7.60931 8.94277 7.83317 8.66663 7.83317H3.99996C3.72382 7.83317 3.49996 7.60931 3.49996 7.33317ZM2.16663 10.6665C2.16663 10.3904 2.39048 10.1665 2.66663 10.1665H8.66663C8.94277 10.1665 9.16663 10.3904 9.16663 10.6665C9.16663 10.9426 8.94277 11.1665 8.66663 11.1665H2.66663C2.39048 11.1665 2.16663 10.9426 2.16663 10.6665Z" fill="#B3BBBF"/>
  </svg>
</template>

<script>
export default {
  name: 'SwapIcon',
};
</script>
