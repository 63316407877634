import { groupBy } from 'lodash-es';
import api from '@/api';

export default {
  state: {
    emergencyServices:[]
  },
  getters: {
    getEmergencyServices: state => {
      return state.emergencyServices;
    },
  },
  mutations: {
    updateEmergencyServices(state, newEmergencyServices) {
      state.emergencyServices = newEmergencyServices;
    }
  },
  actions: {
    async getEmergencyServices(context) {
      const response = await api.referenceBooks.getEmergency();
      context.commit('updateEmergencyServices', response.data.DATA);
    }
  }
};
