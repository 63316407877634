var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"passages scrolling-tables-static"},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('h2',{staticClass:"text-h2"},[_vm._v(_vm._s(_vm.$t("Event log")))])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex flex-grow-1 justify-end",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"primary","outlined":_vm.filterIsEmpty,"width":"150"},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[(!_vm.showFilters)?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("$filter")]),_vm._v(" "+_vm._s(_vm.$t("Filters"))+" ")]:[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("$up")]),_vm._v(" "+_vm._s(_vm.$t("Collapse"))+" ")]],2)],1)],1),_c('filter-component',{ref:"refFilterComponent",attrs:{"show":_vm.showFilters,"data":_vm.filterModel},on:{"getDataFilters":_vm.applyFilter,"filterIsEmpty":function($event){_vm.filterIsEmpty = $event},"changeFilterElement":_vm.changeFilterElement},model:{value:(_vm.filterModel),callback:function ($$v) {_vm.filterModel=$$v},expression:"filterModel"}}),_c('v-data-table',{ref:"refTableObjects",staticClass:"table-striped passages-table",attrs:{"headers":_vm.translatedTableHeaders,"items":_vm.tableItems,"sort-by":"DATE","sort-desc":true,"header-props":{ 'sort-icon': '$swap' },"loading":_vm.tableLoading,"no-data-text":_vm.$t('Data not found'),"no-results-text":_vm.$t('Data not found'),"footer-props":{
      'items-per-page-text': _vm.$t('Records per page') + ':',
      'items-per-page-options': [15, 25, 50, 100],
    },"options":_vm.tableOptions},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:`item.DATE`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.dateHelpers.getFormattedDateCustom(item.DATE, 'DD.MM.YYYY HH:mm:ss')))])]}},{key:`item.REQUEST_ID`,fn:function({ item }){return [_c('div',{staticClass:"passages-link",on:{"click":function($event){return _vm.routerLink(_vm.requestsMenu.link, item)}}},[_vm._v(" "+_vm._s(item.REQUEST_ID)+" ")])]}},{key:`item.REQUEST_V_NAME`,fn:function({ item }){return [_c('div',{staticClass:"passages-link",on:{"click":function($event){return _vm.routerLink(_vm.requestsMenu.link, item)}}},[_vm._v(" "+_vm._s(item.REQUEST_V_NAME)+" ")])]}},{key:`item.IS_PASSED`,fn:function({ item }){return [(item.IS_PASSED)?_c('v-icon',[_vm._v("$check")]):_vm._e()]}},{key:`item.DEVICE_TYPE`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(_vm.DEVICE_TYPE.find((elem) => elem.value == item.DEVICE_TYPE)?.text))+" ")]}},{key:`item.IS_ENTRY`,fn:function({ item }){return [_c('span',{class:{
          'green--text': item.IS_ENTRY,
          'red--text': !item.IS_ENTRY,
        }},[_vm._v(" "+_vm._s(item.IS_ENTRY ? _vm.$t("Entry") : _vm.$t("Exit"))+" ")])]}},{key:`item.ACTIONS`,fn:function({ item }){return [_c('div',{staticClass:"d-inline-flex"},[(item.DATA)?_c('div',{on:{"click":function($event){return _vm.showVideoDialog(item)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"primary","size":"24"}},[_vm._v(" $videoIcon ")])],1):_vm._e(),(item.DATA?.car_shot)?_c('div',{staticClass:"d-block",on:{"click":function($event){return _vm.showImgDialog(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","size":"24"}},'v-icon',attrs,false),on),[_vm._v(" $pictureIcon ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Car photo"))+" ")])],1):_vm._e(),(item.DATA)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","size":"24"},on:{"click":function($event){return _vm.showInfoDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" $informationIcon ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Information"))+" ")]):_vm._e()],1)]}}],null,true)}),_c('InfoDialog',{attrs:{"isDialogActive":_vm.isInfoDialogShow,"information":_vm.currentPassageInformation},on:{"close-dialog":function($event){_vm.isInfoDialogShow = false}}}),_c('AppDialogImg',{attrs:{"is-dialog-img-show":_vm.isDialogImgShow,"img-url":_vm.imgUrl},on:{"close-dialog":function($event){_vm.isDialogImgShow = false}}}),_c('AppDialogWrapper',{attrs:{"isDialogShow":_vm.isDialogVideoShow,"setting":_vm.dialogWrapperSetting},on:{"close-dialog":function($event){_vm.isDialogVideoShow = false}}},[_c('VideoWebSocket',{attrs:{"item":_vm.objectItemDialog}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }