<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Users") }}</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn depressed color="primary" @click="showNewUserForm">
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div v-if="userId !== item.ID">
          <v-menu transition="slide-y-transition" bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="24" class="ml-4" color="primary">
                    $ellipsisIcon
                </v-icon>
            </template>
            <v-list>
              <v-list-item v-if="$store.getters.user.PERMISSION?.is_superadmin" @click="showPermissionsAndProhibitionDialog(item)">
                <v-icon class="mr-2" color="primary">
                  $writingIcon
                </v-icon>
                {{ $t("Permissions") }}
              </v-list-item>

              <v-list-item @click="showUserObjects(item)">
                <v-icon
                  class="mr-2"
                  color="primary"
                >
                  $home
                </v-icon>
                {{ $t("Objects to visit") }}
              </v-list-item>

              <v-list-item @click="showEditUserForm(item)">
                <v-icon class="mr-2" color="primary">
                  $edit
                </v-icon>
                {{ $t("Edit") }}
              </v-list-item>

              <v-list-item @click="blockToggleUser(item)">
                <v-icon
                  class="mr-2"
                  :color="item.STATUS != 2 ? 'red' : 'green'"
                >
                  $lock
                </v-icon>
                {{ item.STATUS != 2 ? $t('Block') : $t('Activate') }}
              </v-list-item>

              <v-list-item @click="showRemoveUserConfirm(item)">
                <v-icon class="mr-2" color="red">
                  $delete
                </v-icon>
                {{ $t("Delete") }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ $t(USER_STATUSES.find((elem) => elem.value == item.STATUS)?.text) }}
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="userFormDialog" max-width="450">
      <v-card>
        <v-icon size="16" class="card__close-btn" @click="userFormDialog = false">
          $close
        </v-icon>
        <v-card-title class="card__title-wrapper">
          <h3 class="card__title" :class="{'card__title_narrow' : !isNewUserForm}">
            {{ isNewUserForm ? $t("Adding a user") : $t("Editing a user") }}
          </h3>
        </v-card-title>
        <v-card-text style="max-width: 410px; margin: 0 auto;" class="text-center p-0 administration-users">
          <v-form ref="userForm" v-model="userFormValid" @keyup.native.enter="userFormSubmit">
            <v-text-field
              v-model="userForm.NAME"
              :rules="userFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>

            <v-text-field
              :value="userForm.ROLE_FUNCTION_NAME"
              :rules="userFormRules.ROLE_FUNCTION_NAME"
              hide-details="auto"
              class="mb-5"
              outlined
              readonly
            >
              <template #append>
                <v-icon size="19" color="primary">
                  $edit
                </v-icon>
              </template>
              <template #prepend-inner>
                <button type="button" class="card__btn-inside-input" @click="isFunctionalRolesDialogShow = true;" />
              </template>
              <template #label>
                {{ $t("Functional role") }}<span class="red--text">*</span>
              </template>
            </v-text-field>

            <v-text-field
              :value="userForm.ROLE_OBJECT_NAME"
              clearable
              clear-icon="$closeCircle"
              hide-details="auto"
              class="mb-5"
              outlined
              readonly
              @click:clear="clearClick"
            >
              <template #append>
                <v-icon size="19" color="primary">
                  $edit
                </v-icon>
              </template>
              <template #prepend-inner>
                <button type="button" class="card__btn-inside-input" @click="isObjectRolesDialogShow = true;" />
              </template>
              <template #label>
                {{ $t("Set of Gates") }}
              </template>
            </v-text-field>

            <v-select
              v-model="userForm.STATUS"
              :items="translateUserStatuses"
              :rules="userFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>

            <v-text-field
              v-model="userForm.LOGIN"
              :rules="userFormRules.LOGIN"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-icon v-if="userForm.LOGIN" @click="copyField(userForm.LOGIN, $t('Username'))">
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("Username") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="userForm.PASSWORD"
              :rules="isNewUserForm ? userFormRules.PASSWORD : []"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-btn
                  v-if="!userForm.PASSWORD"
                  height="20px"
                  class="pa-1 mr-2"
                  color="primary"
                  text
                  @click="userForm.PASSWORD = generatePass()"
                >
                  {{ $t("Generate") }}
                </v-btn>
                <v-icon
                  v-if="userForm.PASSWORD"
                  @click="copyField(userForm.PASSWORD, $t('Password'))"
                >
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("New password") }}<span v-if="isNewUserForm" class="red--text">*</span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="userForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              no-resize
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :class="{'btn_disabled': !userFormValid}"
              @click="userFormSubmit"
            >
              {{ isNewUserForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="userRemoveConfirm"
      :loading="userRemoveLoading"
      @closeDialog="userRemoveConfirm = false"
      @confirm="removeUser"
    >
      <template #title>
        <h3 class="card__title card__title_small">
          {{ $t("Delete user") }} «{{ userForm.NAME }}»?
        </h3>
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore the user data") }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showUserObjectsDialog"
      :objects="objects"
      :title="$t('Objects to visit')"
      :subtitle="userForm.NAME"
      :headers="[{ text: 'NAME', value: 'OBJECT_NAME' }]"
      item-key="OBJECT_ID"
      :is-list-dense="true"
      :show-checkboxes="false"
      @closeDialog="showUserObjectsDialog = false"
    />

    <RadioListDialog
      v-if="isFunctionalRolesDialogShow"
      :objects="functionalRoles"
      :title="$t('Functional roles list')"
      :subtitle="userForm.NAME"
      :headers="[{ text: 'NAME', value: 'NAME' }]"
      :single-select="true"
      :selected-item-id-prop="userForm.ROLE_FUNCTION_ID"
      @closeDialog="isFunctionalRolesDialogShow = false"
      @saveForm="saveFunctionalRole"
      item-key="ID"
    />

    <RadioListDialog
      v-if="isObjectRolesDialogShow"
      :objects="objectRoles"
      :title="$t('Set of Gates')"
      :subtitle="userForm.NAME"
      :headers="[{ text: 'NAME', value: 'NAME' }]"
      :single-select="true"
      :selected-item-id-prop="userForm.ROLE_OBJECT_ID"
      @closeDialog="isObjectRolesDialogShow = false"
      @saveForm="saveObjectRole"
      item-key="ID"
    />

    <PermissionsAndProhibition
      v-if="isPermissionsAndProhibitionDialogShow"
      :title="$t('Permissions and prohibition')"
      :id="userForm.ROLE_FUNCTION_ID"
      :is-editable="false"
      @close-permissions-dialog="isPermissionsAndProhibitionDialogShow = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import { USER_ROLES, USER_STATUSES } from '@/constants';
import CheckboxListDialog from '@/components/blocks/CheckboxListDialog.vue';
import PermissionsAndProhibition from '@/components/blocks/PermissionsAndProhibition.vue';
import RadioListDialog from '@/components/blocks/RadioListDialog.vue';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import { filterModel, tableHeaders } from './data.user';
import { copyToClipboard } from '@/helpers/helpers';

export default {
  name: 'UsersPage',
  components: { ConfirmRemoveDialog, CheckboxListDialog, FilterComponent, PermissionsAndProhibition, RadioListDialog },
  data() {
    return {
      filterModel,
      tableHeaders,
      USER_STATUSES,
      USER_ROLES,

      filterIsEmpty: true,
      isNewUserForm: true,
      objects: [],
      functionalRoles: [],
      objectRoles: [],
      showFilters: false,
      showUserObjectsDialog: false,
      isFunctionalRolesDialogShow: false,
      isObjectRolesDialogShow: false,
      isPermissionsAndProhibitionDialogShow: false,
      tableItems: [],
      tableLoading: false,
      userRemoveConfirm: false,
      userRemoveLoading: false,
      userFormDialog: false,
      userFormValid: false,
      userForm: this.createUserFormModel(),
      userFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        LOGIN: [(v) => !!v || `${this.$t('You need to fill in «Login»')}.`],
        ROLE_FUNCTION_NAME: [(v) => !!v || `${this.$t('You need to fill in «Functional role»')}.`],
        ROLE_OBJECT_NAME: [(v) => !!v || `${this.$t('You need to fill in «Object role»')}.`],
        PASSWORD: [(v) => !!v || `${this.$t('You need to fill in «Password»')}.`],
        STATUS: [(v) => !!v || v === 0 || `${this.$t('You need to fill in «Status»')}.`],
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.model.ID,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateUserRoles() {
      return USER_ROLES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateUserStatuses() {
      return USER_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    this.applyFilter();

    api.roles.getAll(1).then((res) => {
      this.functionalRoles = res.data.DATA;
    })

    api.roles.getAll(2).then((res) => {
      this.objectRoles = res.data.DATA;
    })
  },
  methods: {
    clearClick() {
        this.userForm.ROLE_OBJECT_NAME = '';
        this.userForm.ROLE_OBJECT_ID = '';
    },
    showNewUserForm() {
      if (this.$refs.userForm) this.$refs.userForm.resetValidation();
      this.userForm = this.createUserFormModel();
      this.isNewUserForm = true;
      this.userFormDialog = true;
    },
    showEditUserForm(user) {
      if (!user) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userFormDialog = true;
    },
    showRemoveUserConfirm(user) {
      this.userForm = this.createUserFormModel(user);
      this.userRemoveConfirm = true;
    },
    showUserObjects(user) {
      if (!user) return false;
      this.userForm = this.createUserFormModel(user);
      this.loadObjects().then(() => (this.showUserObjectsDialog = true));
    },
    createUserFormModel(user = {}) {
      return {
        ID: user.ID || null,
        ROLE_FUNCTION_NAME: user.ROLE_FUNCTION_NAME || '',
        ROLE_FUNCTION_ID: user.ROLE_FUNCTION_ID || null,
        ROLE_OBJECT_NAME: user.ROLE_OBJECT_NAME || '',
        ROLE_OBJECT_ID: user.ROLE_OBJECT_ID || '',
        NAME: user.NAME || '',
        STATUS: user.STATUS || 1,
        LOGIN: user.LOGIN || '',
        PASSWORD: '',
        DESCRIPTION: user.DESCRIPTION || '',
      };
    },
    userFormSubmit() {
      if (this.$refs.userForm.validate()) {
        const formData = new FormData();
        Object.keys(this.userForm).forEach((key) => {
          let value = this.userForm[key];
          formData.append(key.toLowerCase(), value);
        });
        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewUserForm) {
          api.users
            .create(formData)
            .then((res) => {
              this.userFormDialog = false;
              this.tableItems.push(res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.users
            .update(this.userForm.ID, formData)
            .then((res) => {
              this.userFormDialog = false;
              const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
              Object.assign(this.tableItems[userIndex], this.userForm);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleUser(user) {
      if (!user || this.userId == user.ID) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userForm.STATUS = this.userForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.userForm).forEach((key) => {
        let value = this.userForm[key];
        value = value === null ? '' : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.users
        .update(this.userForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
          Object.assign(this.tableItems[userIndex], this.userForm);
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
          });
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    removeUser() {
      this.userRemoveLoading = true;
      api.users
        .remove(this.userForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.userRemoveConfirm = false;
            this.userRemoveLoading = false;
            const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
            this.tableItems.splice(userIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.userRemoveConfirm = false;
          this.userRemoveLoading = false;
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    loadObjects() {
      return api.roles.objectRoleGetElements(this.userForm.ROLE_OBJECT_ID).then((res) => {
        const activeObjects = res.data.DATA.filter(role => role.IS_SELECT);
        this.objects = activeObjects;
      });
    },
    copyField(item, label) {
      copyToClipboard(item);
      this.$store.dispatch(SNACK_ADD_MESSAGE, {
        type: 'success',
        text: `${label} ${this.$t('copied')}`,
      });
    },
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter('STATUS');
      api.users
        .getAll({ status })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    generatePass() {
      const CharacterSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!%&*$#^@';
      const lengthPass = 12;
      let password = '';
      for (let i = 0; i < lengthPass; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return password;
    },
    saveObjectRole(objectRole) {
      this.userForm.ROLE_OBJECT_NAME = objectRole.NAME;
      this.userForm.ROLE_OBJECT_ID = objectRole.ID;
      this.isObjectRolesDialogShow = false;
    },
    saveFunctionalRole(functionalRole) {
      this.userForm.ROLE_FUNCTION_NAME = functionalRole.NAME;
      this.userForm.ROLE_FUNCTION_ID = functionalRole.ID;
      this.isFunctionalRolesDialogShow = false;
    },
    showPermissionsAndProhibitionDialog(user) {
      this.userForm = this.createUserFormModel(user);
      this.isPermissionsAndProhibitionDialogShow = true;
    }
  },
};
</script>

<style lang="scss" >
.administration-users {
  .v-input__append-inner {
    z-index: 10;
  }
}
</style>
