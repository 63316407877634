export default function admin({ next, store }) {
  if (!store?.state?.user?.model?.PERMISSION?.web_admin) {
    return next('/login');
  }

  if (!store.state.systemConfig.isConfigLoaded) {
    store.dispatch('fetchSystemConfig');
  }

  if (store.getters.isMobile()) {
    return next('/requests');
  }

  return next();
}
