import axios from 'axios';

export const getAll = (params) => axios({
  url: '/devices',
  method: 'GET',
  params,
});
export const create = (deviceFormData) => axios({
  url: '/devices',
  method: 'POST',
  data: deviceFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const update = (id, deviceFormData) => {
  // обход проблем с методом PUT в Laravel
  deviceFormData.append('_method', 'PUT');
  return axios({
    url: `/devices/${id}`,
    method: 'POST',
    data: deviceFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const remove = (id) => axios({
  url: `/devices/${id}`,
  method: 'DELETE',
});

export const getZones = (id) => axios({
  url: `/devices/${id}/zones`,
  method: 'GET',
});

export const getExternalReaders = (id, isBind) => axios({
  url: `/devices/${id}/reader-external`,
  method: 'GET',
  params: {
    id,
    is_bind: isBind
  }
});

export const addExternalReaders = (id, externalReadersId) => axios({
  url: `/devices/${id}/reader-external`,
  method: 'POST',
  data: {
    id,
    reader_external_id: externalReadersId
  }
});

export const removeExternalReaders = (id, externalReaderId) => axios({
  url: `/devices/${id}/reader-external`,
  method: 'DELETE',
  data: {
    id,
    reader_external_id: externalReaderId
  }
});
