import axios from "axios";
export const getReport = (params, id) =>
  axios({
    url: `/report/${id}/front`,
    method: "GET",
    params,
  });
  export const getExcel = (params,id) =>
  axios({
    url: `/report/${id}/download`,
    method: "GET",
    params: {
      ...params
    },
    responseType:'arraybuffer',
  });
