import { USER_STATUSES, USER_ROLES } from '@/constants';

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    sortable: true,
  },
  {
    text: 'Username',
    value: 'LOGIN',
    sortable: false,
    width: '150px',
  },
  {
    text: 'Status',
    value: 'STATUS',
    sortable: true,
    width: '150px',
  },
  {
    text: 'Functional role',
    value: 'ROLE_FUNCTION_NAME',
    filterable: false,
    sortable: false,
    width: '185px',
  },
  {
    text: 'Set of Gates',
    value: 'ROLE_OBJECT_NAME',
    filterable: false,
    sortable: false,
    width: '185px',
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: '',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
];

export const filterModel = [
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: USER_STATUSES,
    label: 'Status',
  }
];
