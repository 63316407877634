<template>
  <div class="login-page">
    <div class="d-flex align-center fill-height">
      <div class="container">
        <v-row>
          <v-col>
            <v-form ref="form" v-model="valid" class="login-form" @keyup.native.enter="submit">
              <div class="d-flex justify-center mb-2">
                <v-img max-height="80" max-width="80" src="@/assets/images/logo.svg" />
              </div>
              <h4 class="text-h4 mb-6 text-center color-primary">
                {{ $t("Pass ordering system") }}
              </h4>
              <h4 class="text-h4 mb-5 text-center">
                {{ $t("Login to your personal account") }}
              </h4>
              <v-text-field
                v-model="login"
                :label="$t('Username')"
                :rules="loginRules"
                hide-details="auto"
                outlined
                required
                class="mb-3"
              />

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? '$show' : '$hide'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                :label="$t('Password')"
                hide-details="auto"
                outlined
                required
                class="mb-5"
                @click:append="showPassword = !showPassword"
              />

              <v-btn :disabled="!valid" depressed block color="primary" @click="submit">
                {{ $t("Login") }}
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
        <confirm-wrong-login-data v-if="!isCorrectLogPass" @closeDialog="closeWrongLoginDialog" />
      </div>
    </div>
    <div class="login-page__bottom">
      <div class="d-flex">
        <div class="login-page__bottom-year">
          © 1995–2024
        </div>
        <v-icon
          color="primary"
        >
          $infomaticsIcon
        </v-icon>
      </div>
      <div class="login-page__bottom-version">
        Версия 1.0.0.0
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmWrongLoginData from '../components/blocks/ConfirmWrongLoginData.vue';

export default {
  name: 'LoginPage',
  components: { ConfirmWrongLoginData },
  data() {
    return {
      showPassword: false,
      valid: false,
      loginRules: [(v) => !!v || `${this.$t('You need to fill in «Login»')}.`],
      passwordRules: [(v) => !!v || `${this.$t('You must enter «Password»')}.`],
      login: '',
      password: '',
    };
  },
  computed: {
    isCorrectLogPass() {
      return this.$store.getters.getIsCorrectLogPass;
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('logIn', {
          username: this.login,
          password: this.password,
        });
      }
    },
    closeWrongLoginDialog() {
      this.$store.commit('changeLogPassStatus', true);
    }
  },
};
</script>

<style lang="scss">
.login {
  &-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      padding-bottom: 38px;

      &-year {
        align-items: center;
        margin-right: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        color: $primary;
      }
    }
  }

  &-form {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
}
</style>
