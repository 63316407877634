export default {
  state: {
    isDefaultDialogActive: false,
    defaultDialogText: '',
    defaultDialogTitle: '',
    functionOnClose: () => {},
  },
  getters: {
    getDefaultDialogStatus: state => {
      return state.isDefaultDialogActive;
    },
    getDefaultDialogTitle: state => {
      return state.defaultDialogTitle;
    },
    getDefaultDialogText: state => {
      return state.defaultDialogText;
    },
    getDefaultDialogFunctionOnClose: state => {
      return state.functionOnClose;
    },
  },
  mutations: {
    changeDefaultDialogStatus(state, status) {
      state.isDefaultDialogActive = status;
    },
    changeDefaultDialogTitle(state, title) {
      state.defaultDialogTitle = title;
    },
    changeDefaultDialogText(state, text) {
      state.defaultDialogText = text;
    },
    changeDefaultDialogFunctionOnClose(state, func) {
      state.functionOnClose = func;
    },
  },
};
