export default {
  state: {
    isMobile: require('is-mobile'),
  },
  getters: {
    isMobile: state => state.isMobile
  },
  mutations: {
    setIsMobile(state, newIsMobile) {
      state.isMobile = newIsMobile;
    }
  },
  actions: {
    updateIsMobile({ commit }, newIsMobile) {
      commit('setIsMobile', newIsMobile);
    }
  },
}
