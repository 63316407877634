import store from "@/store";
import dateHelpers from "@/helpers/dateHelpers";
export const tableHeaders = [
  {
    text: "Object of visit",
    value: "OBJECT",
    cellClass: "font-weight-medium",
    sortable: false,
    width: "400px",
  },
  {
    text: "Total number of passes (temporary, one-time)",
    value: "AMOUNT_REQUEST",
    filterable: false,
    sortable: false,
    width: "250px",
  },
  {
    text: "Total number of issued passes (temporary, one-time)",
    value: "AMOUNT_APPLY",
    filterable: false,
    sortable: false,
    width: "250px",
  },
];
export const tableHeaders2 = [
  {
    text: "create_date",
    value: "CREATE_DATE",
    cellClass: "font-weight-light",
    sortable: true,
    width: "170px",
  },
  {
    text: "Type of pass",
    value: "MULTI_PASS_NAME",
    cellClass: "font-weight-light",
    sortable: true,
    width: "130px",
  },
  {
    text: "Status",
    value: "STATUS",
    cellClass: "font-weight-light",
    sortable: true,
    width: "150px",
  },
  {
    text: "The start date of the pass",
    value: "BEGIN",
    cellClass: "font-weight-light",
    sortable: true,
    width: "180px",
  },
  {
    text: "The expiration date of the pass",
    value: "END",
    cellClass: "font-weight-light",
    sortable: true,
    width: "180px",
  },
  {
    text: "SLP",
    value: "PASS_CODE",
    cellClass: "font-weight-light",
    sortable: true,
    width: "100px",
  },
  {
    text: "Model the car",
    value: "MODEL",
    cellClass: "font-weight-light",
    sortable: true,
    width: "110px",
  },
  {
    text: "Stamp the car",
    value: "BRAND",
    cellClass: "font-weight-light",
    sortable: true,
    width: "110px",
  },
  {
    text: "Full name of the visitor",
    value: "FULL_NAME",
    cellClass: "font-weight-light",
    sortable: true,
    width: "160px",
  },
  {
    text: "Who created the pass",
    value: "USER",
    cellClass: "font-weight-light",
    sortable: true,
    width: "160px",
  },
];
export const filterModel = [
  {
    name: "PERIOD_TYPE",
    type: "select",
    data: "",
    items: [],
    label: "Period type",
  },
  {
    name: "START_S",
    type: "date-time-picker",
    data: dateHelpers.getWeekAgo().startOf("day").toDate(),
    label: "Start of the period",
  },
  {
    name: "START_PO",
    type: "date-time-picker",
    data: dateHelpers.getToday().endOf("day").toDate(),
    label: "End of the period",
  },
  {
    name: "OBJECT_ID",
    type: "multi-autocomplete",
    data: [],
    items: [],
    label: "Object of visit",
  },
  {
    name: "SLP",
    type: "input-field",
    data: "",
    label: "SLP",
  },
];
