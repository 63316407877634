import store from "@/store";

export function getFilterModels() {
  return [
    {
      name: 'NAME',
      type: 'input-field',
      data: '',
      label: 'Name',
    },
    {
      name: 'STATUS',
      type: 'select',
      data: '',
      items: store.getters?.getEnums['ENUM.Server.STATUS'] ? store.getters?.getEnums['ENUM.Server.STATUS'].map(status => ({ text: status.NAME, value: status.NUMBER })) : [],
      label: 'Status',
    }
  ];
}

export const tableHeaders = [
  {
    text: "ID",
    value: "ID",
    filterable: false,
    sortable: true,
    width: "80px",
  },
  {
    text: "Name",
    value: "NAME",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "200px",
  },
  {
    text: "Status",
    value: "STATUS",
    filterable: false,
    sortable: true,
    width: "150px",
  },
  {
    text: "Load address",
    value: "LOAD_ADDRESS",
    sortable: false,
    width: "250px",
  },
  {
    text: "Nats address",
    value: "NATS_ADDRESS",
    sortable: false,
    width: "150px",
  },
  {
    text: "Nats configuration",
    value: "NATS_CONFIGURATION",
    sortable: false,
    width: "250px",
  },
  {
    text: "Symbolic identifier",
    value: "SID",
    sortable: false,
    width: "250px",
  },
  {
    text: "Central server",
    value: "IS_MASTER",
    sortable: false,
    width: "200px",
  },
  {
    text: "Description",
    value: "DESCRIPTION",
    sortable: false,
    width: "150px",
  },
  {
    text: "Actions",
    value: 'ACTIONS',
    align: 'end',
    sortable: false,
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
  },
];
