<script>
import api from "@/api";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import dateHelpers from '@/helpers/dateHelpers';
import { tableHeaders, tableHeaders2, filterModel } from "./data.reportPasses";
import { REQUEST_TYPES, PERIOD_TYPES, REPORT_TYPES } from "@/constants";
import { COMMON_SET_LOADING } from '@/store/types/mutation-types.js';
import _ from 'lodash';
export default {
  name: "ReportPasses",
  components: { FilterComponent },
  data() {
    return {
      dateHelpers,
      tabsModel: "tab-1",
      REQUEST_TYPES,
      PERIOD_TYPES,
      REPORT_TYPES,
      tableItems: [],
      statisticData: [],
      untouchableStatisticData:[],
      applicationData: [],
      tableHeaders,
      tableHeaders2,
      amountRequest: 0,
      amountApply: 0,
      isFilterApplied: false,
      styleAmount: "",
      // filter
      filterModel: filterModel,
      filterIsEmpty: true,
      showFilters: true,
      filterGates: "",
      filterObjects: "",
      optionsTable: {
        itemsPerPage: 15,
      },
      objectToAdd: {},
      tableOptions: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
    };
  },
  computed: {
    filterModelForTab() {
      return this.tabsModel === "tab-1"
        ? [this.filterModel[2]]
        : this.filterModel;
    },
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },

    translatedTableHeaders2() {
      return this.tableHeaders2.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  mounted() {
    api.objects.getAll().then((res) => {
      res.data.DATA.forEach((object) => {
        filterModel[this.getIndexFromFilter("OBJECT_ID")]?.items.push({
          text: object.NAME,
          value: object.ID,
        });
      });
    });
    REQUEST_TYPES.forEach((object) => {
      filterModel[this.getIndexFromFilter("PASS_TYPE")]?.items.push({
        text: object.text,
        value: object.value,
      });
    });
    PERIOD_TYPES.forEach((object) => {
      filterModel[this.getIndexFromFilter("PERIOD_TYPE")]?.items.push({
        text: object.text,
        value: object.value,
      });
    });
    this.filterModel[this.getIndexFromFilter("PERIOD_TYPE")].data = 1;
  },
  methods: {
    applyFilter() {
      this.tableOptions.page = 1;
      this.$store.commit(COMMON_SET_LOADING);
      const filter = {
        objects: this.getValueFromFilter("OBJECT_ID"),
        p_type: this.getValueFromFilter("PERIOD_TYPE"),
        begin: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_S"), 'DD-MM-YYYY HH:mm'),
        end: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_PO"), 'DD-MM-YYYY HH:mm'),
        data: this.getValueFromFilter("SLP"),
      };
      api.reports
        .getReport(filter, REPORT_TYPES.passReportId)
        .then((res) => {
          const data = res.data.DATA[0].DATA;
          this.statisticData = [];
          this.applicationData = [];
          this.amountRequest = 0;
          this.amountApply = 0;
          data.requests.forEach((object) => {
            let periodAppearence = this.setPassPeriodAppearence(
              object.begin,
              object.end
            );
            this.applicationData.push({
              PASS_CODE: object.code,
              FULL_NAME: object.v_name,
              PASS_TYPE: object.type,
              STATUS: object.state,
              PERIOD: periodAppearence,
              OBJECT_ID: object.object,
              INSIDE: object.inside,
              USER: object.user,
              CREATE_DATE: object.create_date,
              BEGIN: object.begin,
              END: object.end,
              MULTI_PASS_NAME: object.multi_pass_name,
              MODEL: object.model,
              BRAND: object.brand,
            });
          });
          data.total.forEach((object) => {
            this.amountRequest += object.all_request;
            this.amountApply += object.confirm_requests;
            this.statisticData.push({
              OBJECT: object.object,
              AMOUNT_REQUEST: object.all_request,
              AMOUNT_APPLY: object.confirm_requests,
            });
          });
          this.untouchableStatisticData = _.cloneDeep(this.statisticData);
          this.objectToAdd = [
            {
              OBJECT: this.$t('Total by objects'),
              AMOUNT_REQUEST: this.amountRequest,
              AMOUNT_APPLY: this.amountApply,
            },
            {
              OBJECT: this.$t('The total number of vehicles on the “black” list'),
              AMOUNT_REQUEST: data.no_access,
              AMOUNT_APPLY: null,
            },
          ];
          this.statisticData = this.insertObjectEveryNElements(this.untouchableStatisticData, this.objectToAdd, this.optionsTable.itemsPerPage - this.objectToAdd.length)
          this.isFilterApplied = true;
          if (this.amountApply == this.amountRequest) {
            this.styleAmount = "font-size:16px; color:#2DB24A;";
          } else {
            this.styleAmount = "font-size:16px; color:#FF3535;";
          }
        })
        .catch(() => {
          console.error();
        }).finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return (this.dataFilters || this.filterModel)?.find(
        (item) => item.name === field
      ).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    setPassPeriodAppearence(begin, end) {
       if(begin === null && end !== null)
           return( `${this.$t("to")} ${end}`);
        if(begin !== null && end === null)
           return(`${this.$t("from")} ${begin}`);
        if (begin === null && end === null)
           return(`${this.$t("not limited")}`);
        if(begin !== null && end !== null)
          return(`${begin} - ${end}`);

    },
    downloadExcel() {
      this.$store.commit(COMMON_SET_LOADING);
      const filter = {
        objects: this.getValueFromFilter("OBJECT_ID"),
        p_type: this.getValueFromFilter("PERIOD_TYPE"),
        begin: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_S"), 'DD-MM-YYYY HH:mm'),
        end: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_PO"), 'DD-MM-YYYY HH:mm'),
        data: this.getValueFromFilter("SLP"),
        types: [],
      };
      api.reports.getExcel(filter, REPORT_TYPES.passReportId).then((res) => {
        const outputFilename = `${this.$t('Report passes')}.xls`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => this.$store.commit(COMMON_SET_LOADING, false));;
    },
    receiveOptionsTable(options){
      if (this.optionsTable.itemsPerPage === options.itemsPerPage) {
        return
      }
      this.optionsTable = options;
      this.statisticData = this.insertObjectEveryNElements(this.untouchableStatisticData, this.objectToAdd, this.optionsTable.itemsPerPage - this.objectToAdd.length);
    },
    insertObjectEveryNElements(array, objects, interval) {
      const newArray = _.cloneDeep(array);
      if (!Array.isArray(objects)) {
        objects = [objects]; // Преобразуем в массив, если это не массив
    }
      for (let i = interval; i < newArray.length; i += interval + objects.length) {
          newArray.splice(i, 0, ...objects);
      }
      newArray.push(...objects);
      return newArray;
    }
  },
};
</script>
<template>
  <div class="report-passes scrolling-tables-static scrolling-tables-static_mini">
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <router-link to="main" class="d-flex align-center">
          <v-icon class="mr-1" size="20">$back</v-icon>
          {{ $t("To all reports") }}
        </router-link>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tabsModel"
      class="srp-tabs d-flex flex-column"
    >
      <v-row class="mb-5">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2 text--primary">{{ $t("Report passes") }}</h2>
        </v-col>
        <v-tab v-if="isFilterApplied" :href="`#tab-1`">{{
          $t("Statistics")
        }}</v-tab>
        <v-tab v-if="isFilterApplied" :href="`#tab-2`">{{
          $t("List of passes")
        }}</v-tab>
        <v-spacer />
        <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-btn v-show="isFilterApplied" depressed @click="downloadExcel" class="ml-4 btn__export">
            <v-icon class="mr-2" size="20">$download</v-icon>
            {{ $t("Export") }}
          </v-btn>
          <v-btn
            depressed
            class="ml-4"
            color="primary"
            :outlined="filterIsEmpty"
            width="150"
            @click="showFilters = !showFilters"
          >
            <template v-if="!showFilters">
              <v-icon class="mr-2" size="20">$filter</v-icon>
              {{ $t("Filters") }}
            </template>
            <template v-else>
              <v-icon class="mr-2" size="20">$up</v-icon>
              {{ $t("Collapse") }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <v-tab-item class="pt-8" :value="`tab-1`">
        <filter-component
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
          ref="refFilterComponentOne"
        />
        <v-data-table
          v-if="isFilterApplied"
          :headers="translatedTableHeaders"
          :items="statisticData"
          sort-by="NAME"
          :sort-desc="true"
          :items-per-page="-1"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          class="table-striped report-passes"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
          @update:options="receiveOptionsTable"
          ref="refTableObjectsOne"
          :options.sync="tableOptions"
        >
          <template #[`item.OBJECT`]="{ item }">
            <div class="report-passes__footer-item_visit">
              {{ item.OBJECT }}
            </div>
          </template>
          <template #[`item.AMOUNT_APPLY`]="{ item }">
            <div class="report-passes__footer-item report-passes__footer-item_applied">
              {{ item.AMOUNT_APPLY }}
            </div>
          </template>
          <template #[`item.AMOUNT_REQUEST`]="{ item }">
            <div class="report-passes__footer-item report-passes__footer-item_approved">
              {{ item.AMOUNT_REQUEST }}
            </div>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item class="pt-8" :value="`tab-2`">
        <filter-component
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
          ref="refFilterComponentSecond"
        />
        <v-data-table
          v-if="isFilterApplied"
          :headers="translatedTableHeaders2"
          :items="applicationData"
          sort-by="NAME"
          :sort-desc="true"
          :items-per-page="-1"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          class="table-striped"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
          @update:options="receiveOptionsTable"
          ref="refTableObjectsSecond"
        >
          <template #[`item.CREATE_DATE`]="{ item }">
            {{ dateHelpers.getFormattedDateCustom(item.CREATE_DATE, 'DD.MM.YYYY HH:mm:ss') }}
          </template>
          <template #[`item.BEGIN`]="{ item }">
            {{ dateHelpers.getFormattedDateCustom(item.BEGIN, 'DD.MM.YYYY HH:mm') }}
          </template>
          <template #[`item.END`]="{ item }">
            {{ dateHelpers.getFormattedDateCustom(item.END, 'DD.MM.YYYY HH:mm') }}
          </template>
          <template #[`item.STATUS`]="{ item }">
              {{ item.STATUS }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>