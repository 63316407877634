import axios from 'axios';
import { isJsonString } from '@/helpers/helpers';

export const getAll = (params) => axios({
  url: '/server',
  method: 'GET',
  params
});

export const create = (server) => axios({
  url: '/server',
  method: 'POST',
  data: {
    sid: server.SID,
    name: server.NAME,
    status: server.STATUS,
    is_master: server.IS_MASTER,
    load_address: server.LOAD_ADDRESS,
    nats_address: server.NATS_ADDRESS,
    nats_configuration: isJsonString(server.NATS_CONFIGURATION) ? server.NATS_CONFIGURATION : JSON.stringify(server.NATS_CONFIGURATION),
    description: server.DESCRIPTION
  }
});

export const edit = (server) => axios({
  url: `/server/${server.ID}`,
  method: 'PUT',
  data: {
    id: server.ID,
    sid: server.SID,
    name: server.NAME,
    status: server.STATUS,
    is_master: server.IS_MASTER,
    load_address: server.LOAD_ADDRESS,
    nats_address: server.NATS_ADDRESS,
    nats_configuration: isJsonString(server.NATS_CONFIGURATION) ? server.NATS_CONFIGURATION : JSON.stringify(server.NATS_CONFIGURATION),
    description: server.DESCRIPTION
  }
});

export const changeStatus = (id, status) => axios({
  url: `/server/${id}/status`,
  method: 'PUT',
  data: {
    id, status
  }
});

export const remove = (id) => axios({
  url: `/server/${id}`,
  method: 'DELETE',
  data: {
    id
  }
});

export const getGates = (id) => axios({
  url: `/server/${id}/gates`,
  method: 'GET',
  data: {
    id
  }
});
