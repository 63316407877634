<template>
  <objects-list
    v-if="!selectedObject.ID & isLoggedIn"
    @selectObject="(object) => (selectedObject = object)"
  />
  <Selected v-else :object="selectedObject" />
</template>

<script>
import ObjectsList from './ObjectsList.vue';
import Selected from './Selected.vue';

export default {
  name: 'SelfRegistration',
  components: { ObjectsList, Selected },
  data() {
    return {
      selectedObject: {},
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$store.dispatch('logIn', {
        username: 'user_web',
        password: 'user_web',
      });
    }
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang')?.replace('/', '');
    if (lang) {
      this.$cookies.set('lang', lang);
      this.$i18n.locale = lang;
      this.$router.push('/');
    }
  },
};
</script>
