import axios from 'axios';

export const getAll = (roleType, params = {}) => axios({
  url: '/role',
  method: 'GET',
  params: { role_type: roleType, ...params }
});

export const add = (roleType, name, status, description) => axios({
  url: '/role',
  method: 'POST',
  data: {
    role_type: roleType,
    name,
    status,
    description
  }
});

export const edit = (id, roleType, name, status, description) => axios({
  url: `/role/${id}`,
  method: 'PUT',
  data: {
    id,
    role_type: roleType,
    name,
    status,
    description
  }
});

export const changeStatus = (id, roleType, status) => axios({
  url: `/role/${id}/status`,
  method: 'PUT',
  data: {
    id,
    role_type: roleType,
    status
  }
});


export const remove = (id, roleType) => axios({
  url: `/role/${id}`,
  method: 'DELETE',
  data: {
    id,
    role_type: roleType
  }
});

export const functionalRoleGetPermission = (id) => axios({
  url: `/role/${id}/permission`,
  method: 'GET',
  data: {
    id
  }
});

export const functionalRoleChangePermission = (
    id,
    object
  ) => axios({
    url: `/role/${id}/permission`,
    method: 'PUT',
    data: {
      id,
      permission: object
  }
});

export const objectRoleGetElements = (id) => axios({
  url: `/role/${id}/objects`,
  method: 'GET',
  data: {
    id
  }
});

export const objectRoleChangeElements = (id, objectsData) => axios({
  url: `/role/${id}/objects`,
  method: 'PUT',
  data: {
    id,
    objects_data: objectsData
  }
});
