<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import moment from 'moment/moment';

export default {
  components: { DatePicker },
  props: {
    placeholder: {
      type: String,
      default: 'Введите Дату и Время',
    },
    value: {
      type: [Date, Number, String],
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    }
  },
  data() {
    return {
      lang: this.$i18n.locale,
      time: this.value,
    };
  },
  computed: {
    dateFormat() {
      return this.time ? moment(this.time).format('DD.MM.YYYY HH:mm') : '';
    },
  },
  watch: {
    value() {
      this.time = this.value;
    },
  },
  methods: {
    changeCalendarLang(lang) {
      this.lang = lang;
    },
    closeCalendar() {
      this.$emit('closeCalendar', this.time);
    },
    clearDate() {
      this.time = null;
      this.getDate();
    },
    getDate() {
      this.$emit('getDate', this.time);
    },
  },
};
</script>

<template>
  <div
    class="date-time-picker"
    :class="{ dense }"
    :style="width ? `width: ${width}` : ''"
    @click="changeCalendarLang($i18n.locale)"
  >
    <date-picker
      v-model="time"
      type="datetime"
      format="DD.MM.Y HH:mm"
      :default-value="new Date()"
      :show-second="false"
      :minute-step="5"
      :disabled="disabled"
      :lang="lang"
      :color="color"
      @change="getDate"
      @close="closeCalendar"
    >
      <template #input>
        <v-text-field
          v-model="dateFormat"
          :label="placeholder"
          background-color="#fff"
          :color="color"
          :disabled="disabled"
          readonly
          :dense="dense"
          outlined
          hide-details="auto"
        />
      </template>
      <template #icon-calendar>
        <v-icon :color="color" size="20">$calendar</v-icon>
      </template>
      <template #icon-clear>
        <v-icon size="16" @click="clearDate">$closeCircle</v-icon>
      </template>
    </date-picker>
  </div>
</template>

<style lang="scss">
.date-time-picker {
  width: 100%;
  height: 50px;

  &.dense {
    height: 40px;
  }

  .mx-datepicker {
    width: 100%;
    height: 100%;

    .mx-input-wrapper {
      height: 100%;

      .mx-input {
        height: 100%;
      }
    }
  }
}
</style>
