<script>
export default {
  name: 'AppDialogImg',
  props: {
    isDialogImgShow: {
      type: Boolean,
      default: false
    },
    ImgUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialogImg();
    },
    closeDialogImg() {
      this.$emit('close-dialog')
    }
  },
}
</script>

<template>
  <v-dialog
    max-width="1200"
    :value="isDialogImgShow"
    @click:outside="closeDialogImg"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon
        size="16"
        class="card__close-btn"
        @click="closeDialogImg"
      >
        $close
      </v-icon>
        <img
          width="100%"
          class="app-dialog-img__img"
          :src="ImgUrl"
        />
    </v-card>
  </v-dialog>
</template>


<style lang="scss">
.app-dialog-img{
  &__img {
    width: 100%;
  }
}
</style>
