<template>
  <v-dialog
    :value="showDialog"
    max-width="580"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper card__title-wrapper_checkbox-dialog">
        <h2 class="font-weight-medium">
          {{ title }}
        </h2>
        <v-chip v-show="subtitle" color="green" text-color="white" label>
          {{ subtitle }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <p v-show="dialogDescription" class="mb-3 card__description">
          {{ dialogDescription }}
        </p>
        <v-text-field
          v-model="searchObjects"
          append-icon="mdi-magnify"
          :label="$t('Name')"
          outlined
          dense
          hide-details
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">
              $search
            </v-icon>
          </template>
        </v-text-field>
        <div class="radio-list-dialog">
          <template v-for="(item, index) in [...selectedObjects, ...unselectedObjects]" >
            <div class="radio-list-dialog__item" @click="selectItem(item)" :key="`${index} ${item.name}`">
              <template v-if="disabledCheckbox">
                <v-simple-checkbox
                  :value="isSelected"
                  :readonly="disabledCheckbox"
                  :disabled="disabledCheckbox"
                />
              </template>
              <tr v-else class="radio-list-dialog__item-tr">
                <td class="radio-list-dialog__item-radio">
                  <v-radio-group v-model="selectedItemId">
                    <v-radio
                      :value="item.ID"
                      color="primary"
                      @change="selectItem(item)"
                    />
                  </v-radio-group>
                </td>
                <td class="radio-list-dialog__item-text">
                    {{ item[headers[0].value] }}
                </td>
              </tr>
            </div>
          </template>
        </div>
        <v-btn depressed color="primary" width="100%" @click="saveForm">
          {{ $t(buttonText) }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RadioListDialog',
  components: {},
  props: {
    objects: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Save',
    },
    subtitle: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    disabledCheckbox: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    selectedItemIdProp: {
      type: Number,
      default: null
    },
    dialogDescription: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showDialog: true,
      searchObjects: '',
      checkedRadio: null,
      selectedItemId: null,
      selectedItem: null
    };
  },
  computed: {
    selectedObjects() {
      return this.objects.filter((item) => !!item.IS_ACCESS);
    },
    unselectedObjects() {
      return this.objects.filter((item) => !item.IS_ACCESS);
    },
    headersWithoutPaddings() {
      return [{ ...this.headers[0], class: 'px-1', cellClass: 'px-1' }];
    },
  },
  mounted() {
    this.selectedItemId = this.selectedItemIdProp;
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      setTimeout(() => this.$emit('closeDialog', false), 200);
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
      if (('key', e.key === 'Enter')) this.saveForm();
    },
    setRadioValue(e) {
      this.checkedRadio = e;
    },
    saveForm() {
      if (!this.selectedItem) {
        this.closeDialog();
        return;
      }

      this.$emit('saveForm', this.selectedItem);
    },
    selectItem(item) {
      this.selectedItem = item;
      this.selectedItemId = item.ID;
    },
  },
};
</script>
<style lang="scss">

.radio-list-dialog {
  height: 100%;
  max-height: 550px;
  padding-top: 21px;
  overflow-y: auto;

  &__item {
    cursor: pointer;

    &:hover{
      background: #dfeff9 !important;
    }
  }

  &__item-radio {
    padding: 0 16px;
  }

  &__item-text {
    vertical-align: middle;
  }
}
</style>
