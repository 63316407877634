<template>
  <v-dialog max-width="600" :value="isDialogActive" @click:outside="$emit('close-dialog')">
    <v-card class="info-dialog">
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ $t("Service information from the recognition subsystem") }}
        </h3>
      </v-card-title>
      <v-card-text style="margin: 0 auto; color: #272727" class="pb-16">
        <pre>{{ information.trim() }}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoDialog',
  props: {
    isDialogActive: {
      type: Boolean,
      required: true
    },
    information: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.info-dialog {
  width: 100%;
  overflow-x: scroll;
}
</style>
