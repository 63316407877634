<script>
import api from '@/api';
export default {
  name: 'VideoWebSocket',
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
  data: () => {
    return {
      ws: null,
      mediaSource: new MediaSource(),
      sourceBuffer: null,
      lastId: null,
    };
  },
  watch:{
    async item(newValue, oldValue){
      if(newValue !== oldValue) {
        await api.passages.getVideoLogout(this.lastId)
        this.getWebSocketVideo();
      }
    }
  },
  async mounted() {
    await this.$nextTick();
    this.getWebSocketVideo();
  },


  methods: {
    async getWebSocketVideo() {
      if (this.ws) {
        this.ws.close();
      }
      const result = await api.passages.getVideoParams(+this.item.ID)
      const videoParams = await result.data
      this.ws = new WebSocket('ws://'+ videoParams.DATA.wsUrl);

      this.ws.onopen = () => {
        console.log('WS подключенно');
        this.sendWS(videoParams.DATA.token)
      };

     this.mediaSource = new MediaSource(),
      this.mediaSource.addEventListener('sourceopen', () => {

      console.log(this.mediaSource.readyState); // Проверяем состояние mediaSource
      if (this.mediaSource.readyState === 'open') {
        this.sourceBuffer = this.mediaSource.addSourceBuffer('video/mp4; codecs="avc1.4D002A"');
        this.sourceBuffer.addEventListener('error', function (e) {
            console.error('Ошибка при добавлении данных в sourceBuffer:', e);
          });
        }
      });

      this.ws.binaryType = 'arraybuffer';

      const arr = [];
      let totalLength;
      this.ws.onmessage = (event) => {
        let chunkData = event.data;

        if (chunkData === 'up') {
          this.ws.send(videoParams.DATA.VideoData);
        }

        if (typeof chunkData === 'object') {
          arr.push(chunkData);
          totalLength = arr.reduce((total, chunk) => total + chunk.byteLength, 0);
        }
        if (typeof chunkData !== 'object') {
          let combinedBuffer = new ArrayBuffer(totalLength);
          let uint8CombinedBuffer = new Uint8Array(combinedBuffer);
          let offset = 0;
          for (let item of arr) {
            // Создайте Uint8Array для каждого чанка
            let uint8Chunk = new Uint8Array(item);

            // Скопируйте данные из текущего чанка в combinedBuffer
            uint8CombinedBuffer.set(uint8Chunk, offset);

            // Обновите смещение
            offset += item.byteLength;
          }
          if (this.sourceBuffer && this.mediaSource.readyState === 'open') {
            if (!this.sourceBuffer.updating) {
              this.sourceBuffer.appendBuffer(combinedBuffer);
            }
          }
          this.lastId = this.item.ID;
        }
      };
      this.$refs.videoPlayer.src = URL.createObjectURL(this.mediaSource);

      this.ws.onclose = (eventclose) => {
        if (eventclose.code === 1006) {
          console.log('Повторное подключение после ошибки 1006...');
          return this.getWebSocketVideo()
        }
        console.log('соеденение закрыто причина: ' + eventclose.reason);
        console.log('соеденение закрыто причина: ' + eventclose.code);
      }
    },
    sendWS(token) {
      this.ws.send(token);
    },
    openMediaSource() {
      this.mediaSource.addEventListener('sourceopen', () => {

      console.log(this.mediaSource.readyState); // Проверяем состояние mediaSource
      if (this.mediaSource.readyState === 'open') {
        this.sourceBuffer = this.mediaSource.addSourceBuffer('video/mp4; codecs="avc1.4D002A"');
        this.sourceBuffer.addEventListener('error', function (e) {
            console.error('Ошибка при добавлении данных в sourceBuffer:', e);
          });
        }
      });
    },
  },
}
</script>

<template>
  <div class="box-video">
    <video class="video" ref="videoPlayer" controls></video>
  </div>
</template>


<style lang="scss">
.video{
  width: 100%;
  height: 100%;
}
</style>
