<script>
import { RouterView } from "vue-router";
import { tableHeaders } from "./data.reports";

export default {
  name: "ReportsMain",
  data() {
    return {
      tableHeaders,
      tableItems: [
        { NAME: this.$t("Report passes"), link: "passes" },
        { NAME: this.$t("Event Report"), link: "event" }
      ],
      tableLoading: false,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  components: { RouterView },
};
</script>
<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Reports") }}</h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
      </v-col>
    </v-row>
    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="NAME"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.NAME`]="{ item }">
        <a :href="item.link">
          {{ item.NAME }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>
<style></style>
