export default function objects({ next, store }) {
  if (!store?.state?.user?.model?.PERMISSION?.web_objects) {
    return next('/login');
  }

  if (!store.state.systemConfig.isConfigLoaded) {
    store.dispatch('fetchSystemConfig');
  }

  return next();
}
