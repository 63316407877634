<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("External systems") }}</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn depressed color="primary" @click="showNewSystemForm">
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />
    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <router-link :to="`/requests?system_id=${item.ID}`">
            <v-tooltip bottom color="primary">
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" class="ml-4" color="primary" v-on="on">
                  $user
                </v-icon>
              </template>
              {{ $t("A pass") }}
            </v-tooltip>
          </router-link>
          <router-link :to="`/objects?external_system=${item.ID}`">
            <v-tooltip bottom color="primary">
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" class="ml-4" color="primary" v-on="on">
                  $home
                </v-icon>
              </template>
              {{ $t("Objects to visit") }}
            </v-tooltip>
          </router-link>
          <v-icon class="ml-4" color="red" @click="showRemoveSystemConfirm(item)">
            $delete
          </v-icon>
          <v-icon
            class="ml-4"
            :color="item.STATUS != 2 ? 'red' : 'green'"
            @click="blockToggleSystem(item)"
          >
            $lock
          </v-icon>
          <v-icon
            v-if="item.TYPE !== 0"
            class="ml-4"
            color="primary"
            @click="showEditSystemForm(item)"
          >
            $edit
          </v-icon>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ $t(SYSTEM_STATUSES.find((elem) => elem.value == item.STATUS)?.text) }}
        </span>
      </template>
      <template #[`item.KEY`]="{ item }">
        <div class="d-flex justify-space-between" style="width: 200px">
          <span class="d-inline-block text-truncate mr-2">
            {{ item.KEY }}
          </span>
          <v-icon @click="copyKey(item.KEY)">$copy</v-icon>
        </div>
      </template>
      <template #[`item.USER_ID`]="{ item }">
        {{ allUsers.find((elem) => elem.value == item.USER_ID)?.text }}
      </template>
    </v-data-table>

    <v-dialog v-model="systemFormDialog" max-width="580">
      <v-card>
        <v-card-actions class="justify-end">
          <v-icon size="16" class="mr-n3" @click="systemFormDialog = false">
            $close
          </v-icon>
        </v-card-actions>
        <v-card-title class="justify-center mb-6 pl-0 pr-0">
          <h3 class="text-h2 text-center">
            {{
              isNewSystemForm ? $t("Adding an External system") : $t("Editing an External system")
            }}
          </h3>
        </v-card-title>
        <v-card-text  class="text-center pb-16">
          <v-form ref="systemForm" v-model="systemFormValid" @keyup.native.enter="systemFormSubmit">
            <v-text-field
              v-model="systemForm.NAME"
              :rules="systemFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="systemForm.STATUS"
              :items="translatedSystemStatuses"
              :rules="systemFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <v-text-field
              v-model="systemForm.KEY"
              :rules="systemFormRules.KEY"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-btn
                  v-if="!systemForm.KEY"
                  height="20px"
                  class="pa-1 mr-2"
                  color="primary"
                  text
                  @click="systemForm.KEY = generateKey()"
                >
                  {{ $t("Generate") }}
                </v-btn>
                <v-icon v-if="systemForm.KEY" @click="copyKey(systemForm.KEY)">
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("Key") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="systemForm.USER_ID"
              :items="allUsers"
              :rules="systemFormRules.USER_ID"
              :menu-props="{ offsetY: true }"
              hide-details="auto"
              class="mb-5"
              append-icon="$down"
              outlined
            >
              <template #label>
                {{ $t("User") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <v-textarea
              v-model="systemForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :disabled="!systemFormValid"
              @click="systemFormSubmit"
            >
              {{ isNewSystemForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="systemRemoveConfirm"
      :loading="systemRemoveLoading"
      @closeDialog="systemRemoveConfirm = false"
      @confirm="removeSystem"
    >
      <template #title>
        {{ $t("Delete external system") }} «{{ systemForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After removal, it will be impossible to restore the external system") }}.
      </template>
    </confirm-remove-dialog>
  </div>
</template>

<script>
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import { SYSTEM_STATUSES } from '@/constants';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import { tableHeaders, filterModel } from './data.externalSystems';
import { copyToClipboard } from '@/helpers/helpers';

export default {
  name: 'ExternalSystemsPage',
  components: { ConfirmRemoveDialog, FilterComponent },
  data() {
    return {
      filterModel,
      tableHeaders,
      SYSTEM_STATUSES,
      SNACK_ADD_MESSAGE,

      allUsers: [],
      filterIsEmpty: true,
      isNewSystemForm: true,
      tableItems: [],
      tableLoading: false,
      showFilters: false,
      systemRemoveConfirm: false,
      systemRemoveLoading: false,
      systemFormDialog: false,
      systemFormValid: false,
      systemForm: this.createSystemFormModel(),
      systemFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        STATUS: [(v) => !!v || `${this.$t('You need to fill in «Status»')}.`],
        KEY: [(v) => !!v || `${this.$t('You need to fill in the «Key»')}.`],
        USER_ID: [(v) => !!v || `${this.$t('You need to fill «User»')}.`],
      },
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translatedSystemStatuses() {
      return this.SYSTEM_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    this.applyFilter();
    api.users.getAll().then((res) => {
      res.data.DATA.forEach((user) => this.allUsers.push({ text: user.NAME, value: user.ID }));
      this.filterModel[this.getIndexFromFilter('USER')].items = this.allUsers;
    });
  },
  methods: {
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter('STATUS');
      const user = this.getValueFromFilter('USER');
      api.externalSystems
        .getAll({ status, user })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    showNewSystemForm() {
      if (this.$refs.systemForm) this.$refs.systemForm.resetValidation();
      this.systemForm = this.createSystemFormModel();
      this.isNewSystemForm = true;
      this.systemFormDialog = true;
    },
    showEditSystemForm(system) {
      if (!system) return false;
      this.systemForm = this.createSystemFormModel(system);
      this.isNewSystemForm = false;
      this.systemFormDialog = true;
    },
    showRemoveSystemConfirm(system) {
      this.systemForm = this.createSystemFormModel(system);
      this.systemRemoveConfirm = true;
    },
    createSystemFormModel(system = {}) {
      return {
        ID: system.ID || null,
        NAME: system.NAME || '',
        STATUS: system.STATUS || 2,
        TYPE: system.TYPE || 1,
        KEY: system.KEY || '',
        USER_ID: system.USER_ID || '',
        DESCRIPTION: system.DESCRIPTION || '',
      };
    },
    systemFormSubmit() {
      if (this.$refs.systemForm.validate()) {
        const formData = new FormData();
        Object.keys(this.systemForm).forEach((key) => {
          let value = this.systemForm[key];
          value = value === null ? '' : value;
          value = typeof value === 'boolean' ? Number(value) : value;
          formData.append(key.toLowerCase(), value);
        });

        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewSystemForm) {
          api.externalSystems
            .create(formData)
            .then((res) => {
              this.systemFormDialog = false;
              this.tableItems.push(res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.externalSystems
            .update(this.systemForm.ID, formData)
            .then((res) => {
              this.systemFormDialog = false;
              const systemIndex = this.tableItems.findIndex(
                (item) => item.ID === this.systemForm.ID,
              );
              Object.assign(this.tableItems[systemIndex], this.systemForm);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleSystem(system) {
      if (!system) return false;
      this.systemForm = this.createSystemFormModel(system);
      this.isNewSystemForm = false;
      this.systemForm.STATUS = this.systemForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.systemForm).forEach((key) => {
        let value = this.systemForm[key];
        value = value === null ? '' : value;
        value = typeof value === 'boolean' ? Number(value) : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.externalSystems
        .update(this.systemForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const systemIndex = this.tableItems.findIndex((item) => item.ID === this.systemForm.ID);
          Object.assign(this.tableItems[systemIndex], this.systemForm);
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
          });
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    removeSystem() {
      this.systemRemoveLoading = true;
      api.externalSystems
        .remove(this.systemForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.systemRemoveConfirm = false;
            this.systemRemoveLoading = false;
            const systemIndex = this.tableItems.findIndex((item) => item.ID === this.systemForm.ID);
            this.tableItems.splice(systemIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.systemRemoveConfirm = false;
          this.systemRemoveLoading = false;
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    clearFilter() {
      this.filterByStatus = null;
      this.filterByUser = null;
    },
    copyKey(item) {
      copyToClipboard(item);
      this.$store.dispatch(SNACK_ADD_MESSAGE, {
        type: 'success',
        text: this.$t('Key copied'),
      });
    },
    generateKey() {
      const CharacterSet = 'abcdefghijklmnopqrstuvwxyz';
      const lengthPass = 12;

      let password = '';
      for (let i = 0; i < lengthPass; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return password + Date.now();
    },
  },
};
</script>
