import store from "@/store";

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    sortable: true,
    width: '200px'
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    sortable: true,
    width: '150px',
  },
  {
    text: 'Server',
    value: 'SERVER_NAME',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    sortable: false,
    filterable: false,
  }
];

export function getFilterModels() {
  return [
    {
      name: 'NAME',
      type: 'input-field',
      data: '',
      label: 'Name',
    },
    {
      name: 'STATUS',
      type: 'select',
      data: '',
      items: store.getters?.getEnums['ENUM.Gate.STATUS'] ? store.getters?.getEnums['ENUM.Gate.STATUS'].map(status => ({ text: status.NAME, value: status.NUMBER })) : [],
      label: 'Status',
    }
  ];
}
