<template>
  <v-dialog max-width="450" :value="isShow" @click:outside="$emit('close-dialog')">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ $t('Add car') }}
        </h3>
      </v-card-title>

      <v-card-text style="max-width: 370px; margin: 0 auto" class="text-center pb-16">
        <v-form ref="carForm" v-model="formValid" @keyup.native.enter="formSubmit">
          <div>
            <v-text-field
              v-model="carForm.CAR_NUM"
              :rules="carFormRules.CAR_NUM"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("License plate") }}<span class="red--text">*</span>
              </template>
            </v-text-field>

            <v-autocomplete
              v-model="carForm.CURRENT_BRAND_ID"
              :items="carsBrands"
              :rules="carFormRules.CAR_BRAND"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              :no-data-text="$t('Data not found')"
              outlined
            >
              <template #label>
                {{ $t("Car brand") }}<span class="red--text">*</span>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-model="carForm.CURRENT_MODEL_ID"
              :items="carsModels"
              :rules="carFormRules.CAR_MODEL"
              :menu-props="{ offsetY: true }"
              :disabled="!carForm.CURRENT_BRAND_ID"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              :no-data-text="$t('Data not found')"
              outlined
            >
              <template #label>
                {{ $t("Car model") }}<span class="red--text">*</span>
              </template>
            </v-autocomplete>

            <v-btn
              depressed
              block
              color="primary"
              :class="{'btn_disabled': !formValid}"
              @click="formSubmit"
            >
              {{ $t("Save") }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';

export default {
  name: 'CarDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    carsList: {
      type: Object,
      required: true
    },
    accessTypeNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      formValid: false,

      carForm: {
        CAR_NUM: null,
        CURRENT_BRAND_ID: '',
        CURRENT_MODEL_ID: '',
      },

      carFormRules: {
        CAR_BRAND: [(v) => !!v || `${this.$t('You must select «Car brand»')}.`],
        CAR_MODEL: [(v) => !!v || `${this.$t('You must select «Car model»')}.`],
        CAR_NUM: [(v) => !!v || `${this.$t('You need to fill in «License plate»')}.`],
      },
    }
  },
  computed: {
    carsBrands() {
      if (!this.carsList['null']?.length) {
        return [];
      }

      return this.carsList['null'].map(car => ({ text: car.NAME, value: car.ID }));
    },
    carsModels() {
      if (!this.carsList[this.carForm.CURRENT_BRAND_ID]?.length) {
        return [];
      }

      return this.carsList[this.carForm.CURRENT_BRAND_ID].map(car => ({ text: car.NAME, value: car.ID }));
    }
  },
  methods: {
    async formSubmit() {
      try {
        const response = await api.referenceBooks.addCarAccess({ ACCESS_TYPE: this.accessTypeNumber, ...this.carForm });
        this.$emit('add-car', response.data.DATA);
        this.$emit('close-dialog');
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      }
    },
  },
  watch: {
    isShow() {
      for (const key of Object.keys(this.carForm)) {
        this.carForm[key] = '';
      }

      if (this.isShow && this.$refs.carForm) {
        this.$refs.carForm.resetValidation();
      }
    }
  }
}
</script>
