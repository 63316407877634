<template>
  <div>
    <v-col class="pl-0" cols="auto" align-self="center">
      <h2 class="text-h2 text--primary mr-6">{{ $t("Settings") }}</h2>
    </v-col>
    <System />
  </div>
</template>

<script>
import System from './System.vue';

export default {
  name: 'SettingsLayout',
  components: { System },
};
</script>
