import { render, staticRenderFns } from "./objects-icon.vue?vue&type=template&id=1e5e9dca"
import script from "./objects-icon.vue?vue&type=script&lang=js"
export * from "./objects-icon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports