<script>
export default {
  name: 'AppDialogWrapper',
  props: {
    isDialogShow: {
      type: Boolean,
      default: true
    },
    setting: {
      type: Object,
      default: {}
    }
  },
  methods: {
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.closeDialog();
    },
    closeDialog() {
      this.$emit('close-dialog')
    }
  },
}
</script>

<template>
  <v-dialog
    :max-width="setting.dialogMaxWidth"
    :value="isDialogShow"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon
        size="16"
        class="card__close-btn"
        @click="closeDialog"
      >
        $close
      </v-icon>
        <slot></slot>
    </v-card>
  </v-dialog>
</template>
