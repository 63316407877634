import { OBJECT_STATUSES } from '@/constants';

export const tableHeadersExternal = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    width: 180,
  },
  {
    text: 'External system',
    filterable: false,
    value: 'SYSTEM_ID',
    width: 180,
  },
  {
    text: 'SID',
    value: 'SID',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    width: '150px',
  },
  {
    text: 'Logo',
    value: 'LOGO',
    align: 'center',
    filterable: false,
    sortable: false,
    width: '80px',
  },
  {
    text: 'Logo for Wallet',
    value: 'LOGO_WALLET',
    align: 'center',
    filterable: false,
    sortable: false,
    width: '100px',
  },
  {
    text: 'Header',
    value: 'FOOTER',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Type of pass',
    value: 'REQUEST_TYPE',
    sortable: false,
    width: '180px'
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
];

export const tableHeadersLocal = [
  {
    text: 'ID',
    value: 'ID',
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
  },
  {
    text: 'Status',
    value: 'STATUS',
    width: '150px',
  },
  {
    text: 'Logo',
    value: 'LOGO',
    filterable: false,
    sortable: false,
    width: '80px',
  },
  {
    text: 'Logo for Wallet',
    value: 'LOGO_WALLET',
    filterable: false,
    sortable: false,
    width: '100px',
  },
  {
    text: 'Header',
    value: 'FOOTER',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Type of pass',
    value: 'REQUEST_TYPE',
    sortable: false,
    width: '180px'
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
];

export const filterModel = [
  {
    name: 'EXTERNAL_SYSTEM',
    type: 'multi-autocomplete',
    data: [],
    items: [],
    label: 'External system',
  },

  {
    name: 'SID',
    type: 'text-field',
    data: null,
    label: 'SID',
  },
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: OBJECT_STATUSES,
    label: 'Status',
  },
];
