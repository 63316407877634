export default {
  'Order a one-time pass': 'Заказать разовый пропуск',
  'Nothing was found according to your request.': 'По вашему запросу ничего не найдено.',
  'There are no objects.': 'Объектов нет.',
  Select: 'Выбрать',
  Page: 'Страница',
  of: 'из',
  Login: 'Войти',
  'Local user' : 'Локальный пользователь',
  'Loading objects and passes' : 'Загрузка объектов и пропусков ',
  'External SKD' : 'Внешний СКУД',
  'Unknown error!': 'Неизвестная ошибка!',
  'Access denied, contact administrator' : 'Доступ запрещен, обратитесь к администратору',
  'Search by name': 'Поиск по названию',
  'Send request': 'Отправить заявку',
  'The user is not local. Editing permissions is not available' : 'Пользователь не является локальным. Редактирование прав недоступно',
  "By clicking on the 'Continue' button, I consent to the":
    'Нажимая на кнопку «Продолжить», я соглашаюсь на',
  'processing of personal data': 'обработку персональных данных',
  'and accept': 'и принимаю',
  'the Privacy Policy': 'Политику конфиденциальности',
  'Vehicle brand': 'Марка ТС',
  'Vehicle Model': 'Модель ТС',
  'The method of opening the barrier': 'Способ открытия шлагбаума',
  'Barrier': 'Шлагбаум',
  Continue: 'Продолжить',
  'New request': 'Новая заявка',
  'Request №': 'Заявка №',
  'Visit data': 'Данные для посещения',
  'Date of visit': 'Дата посещения',
  'Visit time': 'Время посещения',
  Register: 'Зарегистрировать',
  Save: 'Сохранить',
  'Required field': 'Обязательное поле',
  'Wrong time to visit': 'Некорректное время для посещения',
  'Incorrect visit period': 'Некорректный период посещения',
  'Validity' : 'Период действия',
  'You have entered a past date': 'Вы ввели прошедшую дату',
  'Validation error. Check the correctness of the entered data.':
    'Ошибка валидации. Проверьте правильность введенных данных.',
  Review: 'Рассмотрение',
  Reviewed: 'Рассмотрена',
  Agreed: 'Согласована',
  Confirmed: 'Подтверждена',
  Rejected: 'Отклонена',
  'As soon as the pass is approved, the status of the pass will change to «Confirmed»':
    'Как только пропуск будет одобрен, статус пропуска изменится на «Подтверждена»',
  'Reusable pass': 'Многоразовый пропуск',
  'One time pass': 'Одноразовый пропуск',
  'Request with code': 'Заявка с кодом',
  'not found': 'не найдена',
  'Amount illegal':'Кол-во автомобилей без права доступа',
  'From': 'С',
  'to': 'по',
  Export:'Экспортировать',
  'not limited': 'не ограничен',
  'A pass': 'Пропуск',
  'Passes': 'Пропуска',
  Apply: 'Применить',
  'Not specified': 'Не указано',
  Clear: 'Очистить',
  Statistics: 'Статистические данные',
  'List of passes' : 'Перечень пропусков',
  'List of detections' : 'Перечень распознаваний',
  'List of passages': 'Перечень проходов/проездов',
  'Passage type': 'Тип проезда',

  Local: 'Локальные',
  External: 'Внешние',
  Administration: 'Администрирование',
  'Additionally': 'Дополнительно',
  'User type' : 'Тип пользователя',
  'External system': 'Внешняя система',
  'System': 'Cистема',
  'External systems': 'Внешние системы',
  'Gate' : 'КПП',
  'Roles' : 'Роли',
  'Type' : 'Тип',
  'ID of the pass': 'ID пропуска',
  'Type of emergency service' : 'Тип экстренной службы',
  'External readers': 'Внешние считыватели',
  'Select readers': 'Выберите считыватели',
  'Reader type' : 'Тип считывателя',
  'Confirmation of travel in the direction' : 'Подтверждение проезда по направлению',
  'to the camera':'к камере',
  'from the camera': 'от камеры',
  'Reader address' : 'Адрес считывателя',
  'Symbolic identifier' : 'Символьный идентификатор',
  'Parameter set' : 'Набор параметров',
  'Direction' : 'Направление',
  'Device' : 'Устройство',
  'Reference books' : 'Справочники',
  'Cars' : 'Автомобили',
  'Car photo' : 'Фото ТС',
  'Add сar brand' : 'Добавить марку',
  'Add car model' : 'Добавить модель',
  'Add new car brand' : 'Добавить новую марку',
  'Add new car model' : 'Добавить новую модель',
  'Add car' : 'Добавить автомобиль',
  'Brand' : 'Марка',
  'Model' : 'Модель',
  'Car brand' : 'Марка автомобиля',
  'Car model' : 'Модель автомобиля',
  'License plate' : 'Гос. номер',
  'White list' : 'Белый список',
  'Black list' : 'Черный список',
  'Emergency services' : 'Экстренные службы',
  'Emergency service name' : 'Наименование экстренной службы',
  'Access without a pass' : 'Доступ без пропуска',
  'Adding an external reader' : 'Добавление внешнего считывателя',
  'Editing an external reader' : 'Редактирование внешнего считывателя',
  Users: 'Пользователи',
  'Functional': 'Функциональные',
  'Object' : 'Объект',
  'Set of Gates': 'Набор КПП',
  'Objects': 'Объекты',
  'Functional role': 'Функциональная роль',
  'Functional roles list': 'Список функциональных ролей',
  'Object role': 'Объектная роль',
  'Object roles list': 'Список объектных ролей',
  'Select an object to visit' : 'Выберите объект посещения',
  'Block': 'Заблокировать',
  'Blocking': 'Блокировка',
  'Activate': 'Активировать',
  'Edit': 'Редактировать',
  'Displaying interface sections' : 'Отображение разделов интерфейса',
  'Editing rights to work with gates is not available' : 'Редактирование прав на работу с КПП недоступно',
  'Editing rights to work with access zones is not available' : 'Редактирование прав на работу с зонами доступа недоступно',
  'Editing rights to work with objects to visit is not available' : 'Редактирование прав на работу с объектами посещения недоступно',
  'Editing the rights to change the status of the pass is not available' : 'Редактирование прав на изменение статуса пропуска недоступно',
  'Editing external users is not available' : 'Редактирование внешних пользователей недоступно',
  'Display of the “Administration” interface section is prohibited' : 'Запрещено отображение раздела интерфейса “Администрирование”',
  'Displaying the “Objects to Visit” interface section is prohibited' : 'Запрещено отображение раздела интерфейса “Объекты посещения”',
  'It is forbidden to display the “Omissions” section of the interface' : 'Запрещено отображение раздела интерфейса “Пропуски”',
  'Working with gates' : 'Работа с КПП',
  'Gates list' : 'Список КПП',
  'Adding a gate' : 'Добавление КПП',
  'Editing a gate' : 'Редактирование КПП',
  'Server' : 'Сервер',
  'Adding a server' : 'Добавление сервера',
  'Editing a server' : 'Редактирование сервера',
  'Servers' : 'Серверы',
  'Load address' : 'Адрес для загрузки данных',
  'Nats address' : 'Адрес NATS',
  'Nats configuration' : 'Конфигурация NATS',
  'Nats parameters' : 'Параметры NATS',
  'Nats initialization' : 'Строка инициализации NATS',
  'Central server' : 'Центральный сервер',
  'Working with access zones' : 'Работа с зонами доступа',
  'Working with visiting objects' : 'Работа с Объектами посещения',
  'Changes in the status of the pass' : 'Изменения статуса пропуска',
  'Working with passes' : 'Работа с пропусками',
  'External user' : 'Внешний пользователь',
  Equipment: 'Оборудование',
  'Access zones': 'Зоны доступа',
  'Objects to visit': 'Объекты посещения',
  'Request type' : 'Тип заявок',
  'Type of pass' : 'Тип пропуска',
  'Admission to the visit' : 'Пропуск на посещение',
  'Parking pass' : 'Пропуск на парковку',
  'Editing a pass' : 'Редактирование пропуска',
  'Inspection required' : 'Необходимость досмотра',
  'SLP of the car' : 'ГРЗ автомобиля',
  'SLP' : 'ГРЗ',
  Add: 'Добавить',
  'The total number of vehicles on the “black” list': 'Общее кол-во ТС в “черном” списке',
  'Total number of passes (temporary, one-time)' : 'Общее кол-во пропусков (временных, разовых)',
  'Total number of issued passes (temporary, one-time)': 'Общее кол-во оформленных пропусков (временных, разовых)',
  'Search by requests': 'Поиск по заявкам',
  'Total number of vehicles registered by the system': 'Общее кол-во ТС, зарегистрированных системой',
  'Total number of vehicles with recognized GRZ': 'Общее кол-во ТС с распознанным ГРЗ',
  'Total number of SMP vehicles': 'Общее кол-во автомобилей СМП',
  'Number of manual barrier openings': 'Кол-во ручных открытий шлагбаума',
  'Number of automatic gate openings': 'Кол-во автоматических открытий шлагбаума',
'Number of cars in the territory at the time of the report formation': 'Кол-во автомобилей на территории на момент формирования отчета',

  Filters: 'Фильтры',
  'Driveways':'Проезды',
  Collapse: 'Свернуть',
  'Add a pass': 'Добавить пропуск',
  'Adding a pass': 'Добавление пропуска',
  'Editing a pass': 'Редактирование пропуска',
  Status: 'Статус',
  Period: 'Период посещения',
  'Sign of use' : 'Признак использования',
  'Color schema' : 'Цветографическая схема',
  'Total by objects' : 'Итого по объектам',
  'Report recognitions' : 'Отчет о распознавании',
  'Event Report': 'Отчет о событиях',
  'Report gates' : 'Отчет о проходах и проездах',
  'Amount visitors' : 'Количество посетителей',
  'List of gates': 'Перечень проходов/проездов',
  'Amount cars' : 'Кол-во автомобилей по заявкам',
  "Amount whitelist":'Кол-во автомобилей по «белому» списку',
  'Amount emergency' : 'Кол-во автомобилей экстренных служб',
  'Amount ARM'  :'Кол-во ручных открытий (АРМ Охранника)',
  'Amount button' : 'Кол-во ручных открытий (физ. кнопка)',
  'Amount auto' : 'Кол-во автоматических oткрытий',
  'Amount reject' : 'Кол-во несанкционированных проходов/проездов',
  'manual button' : 'ручных открытий (физ. кнопка)',
  'auto open' : 'автоматических oткрытий',
  'rejected passes' : 'запретов прохода/проезда',
  'Report visitors' : 'Отчет о количестве посетителей на объектах',
  create_date : 'Дата создания',
  'Pass code': 'Код пропуска',
  "Period type" : 'Тип периода',
  'Access period': 'Период доступа',
  'Creation period': 'Период создания',
  'Pass period' : 'Период действия пропуска',
  'Photo' : 'Фотофиксация',
  'Amount emergency cars' : 'Кол-во распознанных ЦГС, имеющих доступ',
  'Amount accuracy' : 'Кол-во распознаваний с достаточной точностью',
  'Amount recognitions' : 'Кол-во распознаваний',
  Accuracy : 'Точность определения',
  'Name of visited objects': 'Наименование объекта посещения',
  'Email sent status': 'Статус отправки email',
  'Pass inside status': 'Нахождение пропуска внутри',
  'The direction of travel': 'Направление проезда',
  'The user who created the pass': 'Пользователь, создавший пропуск',
  'Who created the pass': 'Создавший пропуск ',
  'The user who changed the pass': 'Пользователь, изменивший пропуск',
  'Start of the pass from': 'Начало действия пропуска с',
  'Start of the period' : 'Начало периода',
  'End of the period': 'Окончание периода',
  'Start of the pass till': 'Начало действия пропуска по',
  'End of the pass from': 'Окончание действия пропуска с',
  'End of the pass till': 'Окончание действия пропуска по',
  'Date and time of the first pass from': 'Дата и время первого проход/проезд c',
  'Date and time of the first pass till': 'Дата и время первого проход/проезд по',
  'Data and last exit time from': 'Дата и время последнего выхода/выезда c',
  'Data and last exit time till': 'Дата и время последнего выхода/выезда по',
  'Name of visitor': 'Наименование посетителя',
  'Phone number': 'Номер телефона',
  'Start of the pass': 'Начало действия пропуска',
  'End of the pass': 'Окончание действия пропуска',
  'Date and time of the first pass car': 'Дата и время первого проезда',
  'Date and time of the last exit car': 'Дата и время последнего выезда',
  'Date and time of the first pass': 'Дата и время первого прохода',
  'Date and time of the last exit': 'Дата и время последнего выхода',
  Comments: 'Комментарий',
  'Note': 'Примечание',
  recognitions : 'распознований',
  'accurate recognitions' : 'распознаваний с достаточной точностью',
  'regocnited emergency' : 'распознанных ЦГС',
  Actions: 'Действия',
  Passages: 'Проходы',
  'Event log':'Журнал событий',
  'Information' : 'Информация',
  'Service information from the recognition subsystem' : 'Служебная информация от подсистемы распознавания',
  Settings: 'Настройки',
  'Delete local application': 'Удалить локальную заявку',
  'After deletion, it will be impossible to restore the data of the local pass.':
  'После удаления восстановить данные локальной пропуска будет невозможно.',
  'Remove the external reader': 'Удалить внешний считыватель',
  'After deletion, it will be impossible to restore the data of the external reader.': 'После удаления восстановить данные внешнего считывателя будет невозможно.',
  'Delete the server': 'Удалить сервер',
  'After deletion, it will be impossible to restore the server data.': 'После удаления восстановить данные сервера будет невозможно.',
  'Delete a car': 'Удалить автомобиль',
  "After deletion, it will be impossible to restore the car's data.": 'После удаления восстановить данные автомобиля будет невозможно.',

  Cancel: 'Отмена',
  Delete: 'Удалить',
  'Reusable pass': 'Многоразовый пропуск',
  'You must select «Object of visit».': 'Необходимо выбрать «Объект посещения».',
  'You must select «Reader type».': 'Необходимо выбрать «Тип считывателя».',
  'You must select the value «Multipass»': 'Необходимо выбрать значение «Multipass».',
  'You must enter «Start date»': 'Необходимо заполнить «Дату начала».',
  'You must enter «End date»': 'Необходимо заполнить «Дату окончания».',
  'You must enter «Status»': 'Необходимо заполнить «Статус».',
  'You must enter «Phone number»': 'Необходимо заполнить «Номер телефона».',
  'You must enter «Name of visitor»': 'Необходимо заполнить «Наименование посетителя».',
  'You must enter «Email»': 'Необходимо заполнить «Email».',
  'You must enter «Reader address»': 'Необходимо заполнить «Адрес считывателя».',
  'You must enter «Password»': 'Необходимо заполнить «Пароль».',
  'You must select «Car brand»': 'Необходимо выбрать значение «Марка автомобиля»',
  'You must select «Car model»': 'Необходимо выбрать значение «Модель автомобиля»',
  'Editing a local pass': 'Редактирование локального пропуска',
  'Change password': 'Сменить пароль',
  'Sign out': 'Выйти',
  'Old password': 'Старый пароль',
  'New password': 'Новый пароль',
  'Repeat new password': 'Повторить новый пароль',
  'Do you want to log out?': 'Вы хотите выйти из системы?',
  'Confirm deletion?': 'Подтвердить удаление?',
  'Login to your personal account': 'Вход в личный кабинет',
  'Pass ordering system': 'Система заказа пропусков',
  Username: 'Логин',
  Password: 'Пароль',
  'New password': 'Новый пароль',
  Yes: 'Да',
  No: 'Нет',
  'Sending was not': 'Отправка не выполнялась',
  'Successful sending': 'Успешная отправка',
  'Not used' : 'Не использован',
  'Total': 'Итого',
  'Recognition result' : 'Результат распознавания',
  'Manual arm': 'ручных открытий (АРМ Охранника)',
  'Pass date' : 'Дата и время прохода',
  'Date of the event' :'Дата события',
  'The result of the recognition of the CGS of SMP cars': 'Результат распознавания ЦГС автомобилей СМП',
  'The start date of the pass': 'Дата начала действия пропуска',
  'The expiration date of the pass': 'Дата окончания действия пропуска',
  'Model the car': 'Модель автомобиля',
  'Stamp the car': 'Марка автомобиля',
  'Pass type' : 'Тип проезда',
  Direction:'Направление',
  'Pass result' : 'Проезд совершен',
  Error: 'Ошибка',
  'Attention' : 'Внимание',
  'Pass inside the building, entered through the device of the outer perimeter':
    'Пропуск внутри здания, вошел через устройство внешнего периметра',
  'Pass inside the building, did not enter through the device of the outer perimeter':
    'Пропуск внутри здания, не входил через устройство внешнего периметра',
  'The pass left the building through the outer perimeter device':
    'Пропуск покинул здание через устройство внешнего периметра',
  'The pass left the building through the outer perimeter device after the expiration date':
    'Пропуск покинул здание через устройство внешнего периметра после окончания срока действия',
  To: 'До',
  'Update status': 'Обновить статус',
  'Show route': 'Показать маршрут',
  'Requests info': 'Информация о запросах',
  'Visited object ID': 'Идентификатор объекта посещения',
  Turnstile: 'Турникет',
  'Full name of the visitor': 'ФИО посетителя',
  'Full name': 'ФИО',
  'It is necessary to fill in the «full name»':'Необходимо заполнить «ФИО»',
  Entry: 'Въезд',
  Exit: 'Выезд',
  'Full name, ID of the pass, SID of the pass': 'ФИО, ID пропуска, SID пропуска',
  'Object of visit': 'Объект посещения',
  'Devices' : 'Устройства',
  'Device ID': 'Идентификатор устройства',
  'Device type': 'Тип устройства',
  'Confirmation of entry': 'Подтверждение въезда',
  'Confirmation of departure': 'Подтверждение выезда',
  'Device name': 'Наименование устройства',
  'Direction of passage': 'Направление прохода',
  'Date and time of travel': 'Дата и время проезда',
  'Date and time the records were created from': 'Дата и время создания записи с',
  'Date and time the records were created till': 'Дата и время создания записи по',
  'Date and time the records were created': 'Дата и время создания записи',
  'The passage has been completed': 'Проезд совершен',
  'ID of the pass': 'ID пропуска',
  'Plate num': 'ГРЗ автомобиля',
  'Pass SID': 'SID пропуска',
  'Pass SID': 'SID пропуска',
  'System name': 'Наименование системы',
  'Error message': 'Сообщение об ошибке',
  'Records per page': 'Строк на странице',
  'Electronic lock': 'Электронный замок',
  Email: 'Электронная почта',
  Hours: 'Часы',
  Minutes: 'Минуты',
  'Limit from': 'Лимит с',
  'Limitation of the number of records': 'Ограничение кол-ва записей',
  'Invalid username or password': 'Неверные логин или пароль',
  Close: 'Закрыть',
  Name: 'Наименование',
  Description: 'Описание',
  'Name, description': 'Наименование, описание',
  'Date and time of change': 'Дата и время изменения',
  'Date and time changed from': 'Дата и время изменения с',
  'Date and time changed to': 'Дата и время изменения по',
  'The user who made the change': 'Пользователь, внесший изменение',
  'Lines per page': 'Строк на странице',
  'Data not found': 'Данные не найдены',
  'Viewing data is prohibited' : 'Просмотр данных запрещен',
  'Adding an Access Zone': 'Добавление зоны доступа',
  'Access Zone Editing': 'Редактирование зоны доступа',
  Role: 'Роль',
  'Name, login, description': 'Наименование, логин, описание',
  'Viewing' : 'Просмотр',
  'Creation' : 'Создание',
  'Load' : 'Загрузка',
  'Local user' : 'Локальный пользователь',
  'Loading objects and passes' : 'Загрузка объектов и пропусков',
  'External SKD' : 'Внешний СКУД',
  'Removal' : 'Удаление',
  'Dismiss' : 'Отклонение',
  'Security workstation' : 'АРМ Охранника',
  'API acces' : 'Доступ по API',
  'New > Reviewed' : 'Новая > Рассмотрена',
  'Reviewed > Agreed' : 'Рассмотрена > Согласована',
  'Agreed > Approved' : 'Согласована > Подтверждена',
  'Reviewed > New' : 'Рассмотрена > Новая',
  'Agreed > Reviewed' : 'Согласована > Рассмотрена',
  'Approved > Agreed' : 'Подтверждена > Согласована',
  'Allowed' : 'Разрешено',
  'Forbidden' : 'Запрещено',
  Reports: 'Отчеты',
  'To all reports': 'Ко всем отчетам',
  'Report passes': 'Отчет о заказанных пропусках',
  Generate: 'Сгенерировать',
  'IP address': 'IP адрес',
  'Address' : 'Адрес',
  'NATS address' : 'Адрес NATS',
  'Outer perimeter': 'Внешний периметр',
  Configuration: 'Конфигурация',
  Options: 'Параметры',
  'Initialization string': 'Строка инициализации',
  'Let exit who did not enter from the outer perimeter':
    'Выпускать не вошедших по внешнему периметру',
  'Let exit expired passes from the outer perimeter':
    'Выпускать просроченные пропуска по внешнему периметру',
  'Let exit expired passes from the inner perimeter':
    'Выпускать просроченные пропуска по внутреннему периметру',
  'Let exit expired passes from the outer perimeter':
    'Выпускать просроченные пропуска по внешнему периметру',
  'Name, address, description': 'Наименование, адрес, описание',
  'Name, key, description': 'Наименование, ключ, описание',
  Key: 'Ключ',
  User: 'Пользователь',
  'Delete access zone': 'Удалить зону доступа',
  'Delete equipment': 'Удалить оборудование',
  'Delete user': 'Удалить пользователя',
  'Delete role': 'Удалить роль',
  'Delete gate': 'Удалить КПП',
  'After deletion, it will be impossible to restore access zone data':
    'После удаления восстановить данные зоны доступа будет невозможно',
  'After removal, it will be impossible to restore the hardware':
    'После удаления восстановить данные оборудования будет невозможно',
  'After removal, it will be impossible to restore the external system':
    'После удаления восстановить данные внешний системы будет невозможно',
  'After deletion, it will be impossible to restore the user data':
    'После удаления восстановить данные пользователя будет невозможно',
  'After deletion, it will be impossible to restore role data':
    'После удаления восстановить данные роли будет невозможно',
  'After deletion, it will be impossible to restore gate data':
    'После удаления восстановить данные КПП будет невозможно',
  'You must fill in «Name»': 'Необходимо заполнить «Название»',
  'You need to fill in' : 'Необходимо заполнить',
  'You need to fill in «Status»': 'Необходимо заполнить «Статус»',
  'You need to fill in «Login»': 'Необходимо заполнить «Логин»',
  'You need to fill in «Password»': 'Необходимо заполнить «Пароль»',
  'You need to fill in «Role»': 'Необходимо заполнить «Роль»',
  'You need to fill in «Functional role»': 'Необходимо заполнить «Функциональная роль»',
  'You need to fill in «Object role»': 'Необходимо заполнить «Объектная роль»',
  'You need to fill «Type»': 'Необходимо заполнить «Тип»',
  'You need to fill in «SID»': 'Необходимо заполнить «SID»',
  'You need to fill in «Symbolic identifier»': 'Необходимо заполнить «Символьный идентификатор»',
  'You need to fill in «Name»': 'Необходимо заполнить «Имя»',
  'You must select a value': 'Необходимо выбрать значение',
  'You need to fill in «IP address»': 'Необходимо заполнить «IP адрес»',
  'You need to fill in «Configuration»': 'Необходимо заполнить «Конфигурация»',
  'You need to fill in the «Key»': 'Необходимо заполнить «Ключ»',
  'You need to fill «User»': 'Необходимо заполнить «Пользователь»',
  'You need to fill in «License plate»' : 'Необходимо заполнить «Гос. номер»',
  'You need to fill in «Load address»' : 'Необходимо заполнить «Адрес для загрузки данных»',
  'You need to fill in «NATS address»' : 'Необходимо заполнить «Адрес NATS»',
  'It is necessary to «Change the value»' : 'Необходимо «Поменяй значение»',
  'Permissions': 'Разрешения',
  'Permissions and prohibition': 'Разрешения и запрет',
  copied: 'скопирован',
  'Key copied': 'Ключ скопирован',
  Active: 'Активна',
  Blocked: 'Заблокирована',
  Inside: 'Внутрь',
  Out: 'Наружу',
  'Not set': 'Не задан',
  Administrator: 'Администратор',
  'Pass Bureau': 'Бюро пропусков',
  'Organization (consideration)': 'Организация (рассмотрение)',
  'Organization (agreement)': 'Организация (согласование)',
  'Creating passes': 'Создание пропусков',
  Loading: 'Загрузка',
  Systemic: 'Системное',
  Host: 'Принимающая сторона',
  'Common data': 'Общие данные',
  'Add field': 'Добавить поле',
  'Field name': 'Наименование поля',
  'from 08:00 to 20:00': 'с 08:00 до 20:00',
  'File is loading': 'Идет загрузка файла',
  'File uploaded': 'Файл загружен',
  'Upload file': 'Загрузить файл',
  'This field must contain only numbers': 'Это поле должно содержать только цифры',
  Download: 'Скачать',
  Replace: 'Заменить',
  Logo: 'Логотип',
  'Logo for Wallet': 'Логотип для Wallet',
  'Agreement for the processing of personal data': 'Соглашение обработки персональных данных',
  'Privacy Policy': 'Политика конфиденциальности',
  'Error getting fields': 'Ошибка получения полей',
  'Adding local object to visit': 'Добавление локального объекта посещения',
  'Editing local object to visit': 'Редактирование локального объекта посещения',
  Header: 'Колонтитул',
  'Click to upload the .png file': 'Нажмите для загрузки файла в формате .png',
  'Recommended file resolution 150x150': 'Рекомендуемое разрешение файла 150x150',
  'Delete object to visit': 'Удалить объект посещения',
  'Delete external system': 'Удалить внешнюю систему',
  'After deletion, it will be impossible to restore the data of the visited object':
    'После удаления восстановить данные объекта посещения будет невозможно',
  'Loading error': 'Ошибка загрузки',
  'Adding equipment': 'Добавление оборудования',
  'Equipment editing': 'Редактирование оборудования',
  'Adding a user': 'Добавление пользователя',
  'Adding a role': 'Добавление роли',
  'Editing a user': 'Редактирование пользователя',
  'Editing a role': 'Редактирование роли',
  'Adding an External system': 'Добавление внешней системы',
  'Editing an External system': 'Редактирование внешней системы',
  'Upload from file': 'Загрузка из файла',
  'You can upload .csv file format only': 'Загрузить можно только файл формата .csv',
  'Incorrect file or data format': 'Неверный файл или формат данных',
  consentPersonalDataPart1: 'Я\xa0принимаю',
  consentPersonalDataPart2: 'согласие на\xa0обработку персональных данных',
  consentPersonalDataValidation: 'Для подачи заявки необходимо поставить галочку в\xa0поле "Я\xa0принимаю согласие на\xa0обработку персональных данных"',
  'The initial status of the pass': 'Начальный статус пропуска',
  singlePass: 'Одноразовые',
  'Multipass': 'Многоразовый',
  singleAndMultiPass: 'Одноразовые и многоразовые',
  possibleOmissionOptions: 'Возможные варианты пропусков',
  defaultPassType: 'Тип пропуска по умолчанию',
  systemValue: 'Системное значение',
  'Related request' : 'Связанная заявка',
  'Create a parking request' : 'Создать заявку на парковку',
  'Automatic': 'Автоматическое',
  'Manual': 'Ручное',
  'Without opening': 'Без открытия',
  'The presence of a pass': 'Наличие пропуска',
  'Use Cyrillic characters to enter the State number': 'Используйте символы кириллицы для ввода Гос. номера',
  'Type of device opening': 'Тип открытия устройства',
};
