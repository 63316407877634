import { showDefaultDialog } from '@/helpers/helpers';
import i18n from '@/plugins/vueI18n';

export default function auth({ next, store }) {
  const isMobile = store.getters.isMobile();
  /* 4 - id ROLE без доступа в админку, роль для создания заявки на разовый пропуск */
  if (store.getters.isLoggedIn) {

    if (isMobile) {
      return next('/requests');
    }

    if (store?.state?.user?.model?.PERMISSION?.web_admin) {
      return next('/administration/users');
    }

    if (store?.state?.user?.model?.PERMISSION?.web_objects) {
      return next('/objects');
    }

    if (store?.state?.user?.model?.PERMISSION?.web_requests) {
      return next('/requests');
    }

    if (store?.state?.user?.model?.PERMISSION?.web_pass) {
      return next('/passages');
    }

    showDefaultDialog({
      title: i18n.t('Attention'),
      text: i18n.t('Access denied, contact administrator'),
      functionOnClose: () => {
        store.dispatch('logout');
        return next('/login');
      }
    });

    return;
  }

  return next();
}
