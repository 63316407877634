export default {
  Примечание: 'Comments',
  'ФИО посетителя': 'Full name of the visitor',
  'Электронная почта': 'Email',
  'Контактный телефон': 'Phone number',
  'Заявка успешно добавлена.': 'Request added successfully.',
  'Произошла неизвестная ошибка.': 'Unknown error.',
  Оборудование: 'Equipment',
  'Локальная система': 'Local system',
  'Настройка СКУД': 'ACS setup',
  'IP адрес NATS сервера': 'IP address of NATS server',
  'Порт подключения к NATS-серверу': 'Port of NATS server',
  'Имя узла SMTP': 'SMTP host name',
  'Имя пользователя SMTP': 'SMTP username',
  'Пароль пользователя SMTP': 'SMTP password',
  'IP адрес SMTP сервера': 'SMTP server IP address',
  'Флаг согласования заявок в два этапа': 'Application approval flag in two stages',
  'Флаг включения/выключения режима многоразового прохода по пропуску':
		'Flag for enabling/disabling the mode of multiple passage by the request',
  'Разрешение на выход через внешний периметр при отсутствии входа':
		'Let exit who did not enter from the outer perimeter',
  'Разрешение на выход через внешний периметр после окончания срока действия пропуска':
		'Let exit expired passes from the outer perimeter',
  'Разрешение на выход через внутренние устройства после окончания срока действия пропуска':
		'Let exit expired passes from the inner perimeter',
  'Зона успешно изменена.': 'Zone changed successfully.',
  'Зона успешно добавлена.': 'Zone added successfully.',
  'Зона успешно удалена.': 'The zone was deleted successfully.',
  'Действие запрещено. Привязано устройство.': 'Action prohibited. The device is linked.',
  'Внешняя система успешно добавлена.': 'The external system has been successfully added.',
  'Внешняя система успешно изменена.': 'The external system has been successfully modified.',
  'Внешняя система успешно удалена.': 'The external system was successfully deleted.',
  'Учетная запись успешно создана.': 'The account was successfully created.',
  'Учетная запись успешно обновлена.': 'The account was successfully updated.',
  'Учетная запись успешно удалена.': 'The account has been successfully deleted.',
  'Устройство успешно добавлено.': 'Device added successfully.',
  'Устройство успешно изменено.': 'Device changed successfully.',
  'Устройство успешно удалено.': 'The device was successfully removed.',
  'Параметры заявки успешно обновлены.': 'Request parameters updated successfully.',
  'Параметры успешно обновлены.': 'Settings updated successfully.',
  'Заявка успешно изменена.': 'The request has been successfully modified.',
  'Заявка успешно удалена.': 'Application has been successfully deleted.',
  'Объект успешно добавлен.': 'The object was added successfully.',
  'Объект успешно изменен.': 'The object has been successfully modified.',
  'Объект успешно удален.': 'The object was successfully deleted.',
  'Заявки успешно обновлены.': 'Requests updated successfully.',
  'Заявки успешно добавлены.': 'Requests added successfully.',
  consentPersonalDataPart1: 'I\xa0agree to\xa0the',
  consentPersonalDataPart2: 'processing of\xa0personal data',
  consentPersonalDataValidation: 'To\xa0submit a\xa0request, you must tick the "I\xa0agree to\xa0the processing of\xa0personal data" box.',
  'The initial status of the pass': 'The initial status of the pass',
  singlePass: 'Single',
  multiPass: 'Multiple',
  singleAndMultiPass: 'Single and multiple',
  possibleOmissionOptions: 'Possible pass options',
  defaultPassType: 'Default pass type',
  systemValue: 'System value',
};
