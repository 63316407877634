import { render, staticRenderFns } from "./ObjectsList.vue?vue&type=template&id=749a622e&scoped=true"
import script from "./ObjectsList.vue?vue&type=script&lang=js"
export * from "./ObjectsList.vue?vue&type=script&lang=js"
import style0 from "./ObjectsList.vue?vue&type=style&index=0&id=749a622e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749a622e",
  null
  
)

export default component.exports