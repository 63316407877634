export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'License plate',
    value: 'CAR_NUM',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Brand',
    value: 'BRAND',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Model',
    value: 'MODEL',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    sortable: false
  },
];

export const emergencyHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'Emergency service name',
    value: 'NAME',
    filterable: false,
    sortable: false,
    width: '100px',
  },
  {
    text: 'Access without a pass',
    value: 'FREE_ENTRANCE',
    filterable: false,
    sortable: false,
    width: '100px',
  }
]

