<template>
  <component :is="layout">
    <snacks-list />

    <router-view />

    <v-overlay :value="isLoading" z-index="203">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <AppDefaultDialog />
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SnacksList from './components/main/SnacksList';
import { USER_UPDATE_ACTIVITY } from './store/types/action-types';
import urls from '@/helpers/urls.js';
import store from './store';
import AppDefaultDialog from './components/blocks/AppDefaultDialog.vue';

export default {
  name: 'App',
  components: { SnacksList, AppDefaultDialog },
  provide() {
    return { urls };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.common.isLoading,
    }),
    layout() {
      const layout = this.$route.meta.layout || 'main';
      return () => import(`@/layouts/${layout}.vue`);
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  watch: {
    isLoggedIn(isLoggedIn) {
      return this.userRouteHandler(isLoggedIn);
    },
  },
  mounted() {
    setInterval(
      () => {
        if (this.isLoggedIn) this.updateActivity();
      },
      document.location.href.match(/check-request/) ? 5000 : 30000,
    );
    if (this.$cookies.get('lang')) this.$i18n.locale = this.$cookies.get('lang');
    if (this.isLoggedIn) store.dispatch('getEnums');
  },
  methods: {
    ...mapActions({
      updateActivity: USER_UPDATE_ACTIVITY,
    }),
    userRouteHandler(isLoggedIn) {
      if (!isLoggedIn) return this.$router.push('/login');
      if (isLoggedIn && this.$route.name === 'Login') return (document.location = '/login');
    },
  },
};
</script>

<style lang="scss">
@import "styles/main";
</style>
