import { groupBy } from 'lodash-es';
import api from '@/api';

export default {
  state: {
    cars: []
  },
  getters: {
    getCars: state => {
      return state.cars;
    },
    carsGroupedByBrand: state => {
      return groupBy(state.cars, 'BRAND_ID');
    },
    carsBrands: (state, getters) => {
      if (!getters.carsGroupedByBrand['null']?.length) {
        return [];
      }

      return getters.carsGroupedByBrand['null'].map(car => ({ text: car.NAME, value: car.ID, translate: car.MULTI_DATA }));
    }
  },
  mutations: {
    updateCars(state, newCars) {
      state.cars = newCars;
    }
  },
  actions: {
    async getCars(context) {
      const response = await api.referenceBooks.getAllCars();
      context.commit('updateCars', response.data.DATA);
    }
  }
};
